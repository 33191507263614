(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('ArticleCtrl', ArticleCtrl);

    ArticleCtrl.$inject = ['api', '$location', '$routeParams', 'cuUser', '$sce', '$window', 'toastr', '$anchorScroll', '$compile'];
    function ArticleCtrl(api, $location, $routeParams, cuUser, $sce, $window, toastr, $anchorScroll, $compile) {
        var vm = this;
        vm.path = $location.path();
        vm.itemId = parseInt($routeParams.id);
        vm.content = {};
        vm.memberId = cuUser.memberId() == null ? 0 : cuUser.memberId();
        vm.Bookmark = Bookmark;       

        vm.Email = Email;
        vm.toolId = 0;
        vm.url = '';
        vm.ShowBottom = false;
        vm.BookmarkProperties = {
            isBookmarked: false,
            bookmarkIcon: '',
            bookmarkStyle: ''
        }

        //MOB-172: Added this function for anchors navigation 
        vm.scrollTo = function (anchorid) {
            event.preventDefault();
            $location.hash(anchorid);
            $anchorScroll();
        }

        
        vm.showCustomToast = function () {
            var quote, theme, actionQuote, action = GoToLibrary;
            if (vm.BookmarkProperties.isBookmarked) {
                quote = 'Removed from Library!';
                actionQuote = '';
                theme = 'toast-remove-bookmark';
            }
            else {
                quote = 'Bookmarked!';
                actionQuote = 'View Library'
                theme = 'toast-bookmark';
                //action = GoToLibrary;
            }

            toastr.info(actionQuote, quote, {
                iconClass: theme,
                onTap: action
            });
        };

        //var url = 'http://localhost:49506/mobile/#/';
        api.site.url().then(function (data) {
            vm.url = data.Data;

        }, function (error) {

        });

        if (vm.path.indexOf('/blogs/') > -1) {
            vm.toolId = 25;
            api.blog.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This article is available only to Callahan product subscribers.";
                    $location.path("account/" + vm.itemId + '/login/' + vm.toolId);
                }
                else {
                    vm.content = data.Data;
                    if (data.Data.BodyBottom !== '') vm.ShowBottom = true;
                    vm.content.Body = $sce.trustAsHtml(data.Data.Body);
                    vm.content.BodyBottom = $sce.trustAsHtml(data.Data.BodyBottom);
                    CreateCanonical(vm.content.CustomUrl);
                    updateBookmarkFlag();
                }
            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
            });
        }
        else if (vm.path.indexOf('/articles/') > -1) {            
            vm.toolId = 17;
            api.article.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This article is available only to Callahan product subscribers.";
                }
                else {
                    vm.content = data.Data;

                    //compile the body(content html) to register the ng-click to the DOM because of dynamically generated html
                    var $contentDiv = $(data.Data.Body);
                    angular.element(document.getElementById("displaycontent")).append($contentDiv);

                    var Contentscope = angular.element($contentDiv).scope();
                    $compile($contentDiv)(Contentscope);

                    if (data.Data.BodyBottom !== '') vm.ShowBottom = true;
                    vm.content.BodyBottom = $sce.trustAsHtml(data.Data.BodyBottom);
                    vm.content.Body = $sce.trustAsHtml(vm.content.Body);
                    //$sce.trustAsHtml('<p>He also envisions a product whereby members can directly donate to a ministry of their choosing.</p><div data-ng-dfp-ad="div-gpt-ad-14988252010170</div>'); //
                    vm.content.Error = null;
                    CreateCanonical(vm.content.CustomUrl);
                    updateBookmarkFlag();
                }

            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
                $location.path("account/" + vm.itemId + '/login/' + vm.toolId);
            });
        }
        else if (vm.path.indexOf('/press-center/') > -1) {
            vm.toolId = 30;
            api.press.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This press release is available only to Callahan product subscribers.";
                }
                else {
                    vm.content = data.Data;
                    vm.content.Body = $sce.trustAsHtml(data.Data.Body);
                    vm.content.Error = null;
                    vm.content.DesktopMessage = vm.content.DocumentUrl == null ? true : false;
                    CreateCanonical(vm.content.CustomUrl);
                    updateBookmarkFlag();
                }

            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
                $location.path("account/" + vm.itemId + '/login/' + vm.toolId);
            });
        }

        // Bookmark the article if "library" action is present
        if ($location.search().hasOwnProperty('action')) {
            var action = $location.search()['action'];
            if (action === 'library') {
                var data = {
                    MemberId: vm.memberId,
                    ContentId: vm.itemId
                };
                api.member.isBookmarked(data).then(function (result) {
                    if (!result.Data) {
                        var item = {
                            ArticleId: vm.itemId,
                            toolId: vm.toolId
                        };
                        Bookmark(item);
                    }
                    else {
                        vm.showCustomToast();
                    }
                }, function (error) {
                    // error handling
                    vm.content = "error: " + error;
                });
            }
        }

        function updateBookmarkFlag() {
            var data = {
                MemberId: vm.memberId,
                ContentId: vm.content.ArticleId
            };

            api.member.isBookmarked(data).then(function (result) {
                vm.BookmarkProperties.isBookmarked = result.Data;
                vm.BookmarkProperties.bookmarkIcon = vm.BookmarkProperties.isBookmarked ? "fa fa-bookmark-o" : "fa fa-bookmark";
                vm.BookmarkProperties.bookmarkStyle = vm.BookmarkProperties.isBookmarked ? "border:2px solid #70090a;" : '';
                //debugger
                //if ($location.search().hasOwnProperty('action')) {
                //    var action = $location.search()['action'];
                //    if (action === 'library') {
                //        if (!vm.BookmarkProperties.isBookmarked) {
                //            var item = {
                //                MemberId: vm.memberId,
                //                ContentId: vm.itemId
                //            };
                //            Bookmark(item);
                //        }
                //        else {
                //            $location.search('action', null);
                //        }
                //    }
                //}
            }, function (error) {
                // error handling
                vm.content = "error: " + error;
            });
        }

        function CreateCanonical(customUrl) {
            //remove pre-existing canonical
            var elements = document.getElementsByTagName("link");
            //console.log(elements);
            angular.forEach(elements, function (value, key) {
                if (value.rel === "canonical")
                    document.getElementsByTagName('head')[0].removeChild(value);
            });

            //--------------- BEGIN canonical tag ----------------------
            var metaTag = document.createElement('link');
            metaTag.rel = "canonical";
            metaTag.href = "http://www.creditunions.com/" + customUrl;
            document.getElementsByTagName('head')[0].appendChild(metaTag);
            //--------------- END canonical tag ----------------------

        }

        function GoToLibrary() {
            $location.path("my-library").search({});
        }
       
        function Bookmark(item) {
            if (!vm.memberId) {
                $location.path("account/" + vm.itemId + '/login/' + vm.toolId);//.search({action: 'library'});
            } else if (item.TitleUrl !== null) {
                var data = {
                    MemberId: vm.memberId,
                    ContentId: item.ArticleId,
                    ToolId: vm.toolId
                };

                api.member.Bookmark(data).then(function (result) {
                    result.Data;
                    vm.showCustomToast();
                    updateBookmarkFlag();
                }, function (error) {

                    // error handling
                    vm.content = "error: " + error;
                });
            }
        }

        function Email(content) {

            if (content.RedirectUrl.indexOf('/blogs/') > -1)
                $window.open(vm.url + content.ArticleId + '/email/25', '_blank');
            else if (content.RedirectUrl.indexOf('/articles/') > -1)
                $window.open(vm.url + content.ArticleId + '/email/17', '_blank');
            else if (content.RedirectUrl.indexOf('/press-center/') > -1)
                $window.open(vm.url + content.ArticleId + '/email/30', '_blank');
        }        
    }
})();