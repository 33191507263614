(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('SidebarCtrl', ['$location', 'cuUser', 'api', function ($location, cuUser, api) {
            var vm = this;
            vm.readOpen = false;
            vm.searchOpen = false;
            vm.connectOpen = false;
            vm.myOpen = false;
            vm.login = login;            
            vm.memberId = cuUser.memberId() == null ? 0 : cuUser.memberId();
            vm.toggleRead = toggleRead;
            vm.toggleSearch = toggleSearch;
            vm.toggleConnect = toggleConnect;
            vm.toggleSubscription = toggleSubscription;
            vm.access = true;
            vm.executiveaccess = true;
            if (vm.memberId > 0) {
                api.member.getAccessDetails(vm.memberId).then(function (data) {
                    if (data != null && data !== 0) {
                        if (data.companysubscription == "N") {
                            if (data.companytype == "S")
                                vm.access = false;
                            else if (data.companytype == "CU")
                                vm.executiveaccess = false;
                        }
                        else if (data.companytype == "S" && data.companysubscription == "SC" && data.productaccess == false) {
                            vm.access = false;
                            vm.executiveaccess = false;
                        }
                    }
                    else
                        return;
                }, function (error) {
                });
            }

            function toggleRead() {
                
                vm.readOpen = !vm.readOpen;
                if (vm.readOpen) {
                    vm.searchOpen = false;
                    vm.connectOpen = false;
                    vm.myOpen = false;
                }
            }

            function toggleSearch() {
                vm.searchOpen = !vm.searchOpen;
                if (vm.searchOpen) {
                    vm.readOpen = false;
                    vm.connectOpen = false;
                    vm.myOpen = false;
                }
            }

            function toggleConnect() {
                vm.connectOpen = !vm.connectOpen;
                if (vm.connectOpen) {
                    vm.readOpen = false;
                    vm.searchOpen = false;
                    vm.myOpen = false;
                }
            }

            function toggleSubscription() {
                vm.myOpen = !vm.myOpen;
                if (vm.myOpen) {
                    vm.readOpen = false;
                    vm.searchOpen = false;
                    vm.connectOpen = false;
                }
            }

            function login(pageId) {               
                vm.memberId = cuUser.memberId();
                if (angular.isUndefined(vm.memberId)) {
                    $location.path("account/0/login/" + pageId);
                }
                else {                 
                    if(pageId==7)
                        $location.path("/my-library");              
                    else if (vm.access) {
                        if (pageId == 8) 
                            $location.path("cusearch/");
                        else if (pageId == 9) 
                            $location.path("bystate/");
                        else  if (pageId == 10 && vm.executiveaccess) 
                            $location.path("executive/");                        
                    }
                    else
                        $location.path("#/");
                }               
            }
        }]);
})();