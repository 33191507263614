(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('email', emailDirective);

    function emailDirective() {
        return {
            scope: {},
            templateUrl: 'email.html',
            controller: 'EmailCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('EmailCtrl', EmailCtrl);

    EmailCtrl.$inject = ['api', 'cuUser', '$routeParams', '$window', '$location'];
    function EmailCtrl(api, cuUser, $routeParams, $window, $location) {
        var vm = this;
        vm.memberId = cuUser.memberId() == null ? 0 : cuUser.memberId();
        vm.itemId = parseInt($routeParams.id);
        vm.toolId = parseInt($routeParams.toolId);
        vm.ok = ok;
        vm.userAgent = $window.navigator.userAgent;
        vm.ToEmail = '';
        vm.Message = '';

        if (vm.toolId == 25) //blog
        {
            api.blog.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This article is available only to Callahan product subscribers.";
                    var toolId = 25;
                    $location.path("account/" + vm.itemId + '/login/' + toolId);
                }
                else {
                    vm.content = data.Data;
                }
            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
            });
        }
        else if (vm.toolId == 17) { //articles
            api.article.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This article is available only to Callahan product subscribers.";
                }
                else {
                    vm.content = data.Data;
                    vm.content.Error = null;
                }

            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
                var toolId = 17;
                $location.path("account/" + vm.itemId + '/login/' + toolId);
            });
        }
        else if (vm.toolId == 30) { // press center
            api.press.getById(vm.itemId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data) || data.Data == null) {
                    vm.content.Headline = "This press release is available only to Callahan product subscribers.";
                }
                else {
                    vm.content = data.Data;
                    vm.content.Error = null;
                }

            }, function (error) {
                // error handling
                vm.content.Headline = "This article is available only to Callahan product subscribers.";
                var toolId = 30;
                $location.path("account/" + vm.itemId + '/login/' + toolId);
            });
        }




        if (vm.memberId > 0) {
            api.member.myprofile(vm.memberId)
                .then(function(data) {
                        vm.Name = data.Data.Member.FirstName + ' ' + data.Data.Member.LastName;
                        vm.Email = data.Data.Member.Email;
                    },
                    function(error) {
                        // error handling
                        vm.content = "error";
                    });
        }

        function ok(form) {
            //send email
            vm.Data = {
                FromEmail : vm.Email,
                FromName : vm.Name,
                Message : vm.Message,
                ToEmail : vm.ToEmail,
                ItemId : vm.itemId,
                ToolId : vm.toolId,
                UserAgent : vm.userAgent,
                MemberId: vm.memberId,
                ArticleTitle: vm.content.Headline
            };
            api.article.email(vm.Data)
                .then(function (data) {
                    $location.path(data.Data + '/confirmation/');




                    },
                    function (error) {
                        // error handling
                        vm.content = "error";
                    });


        }
    }
})();
