(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('myProfile', myProfileDirective);

    function myProfileDirective() {
        return {
            scope: {},
            templateUrl: 'demo/account/myProfile.html',
            controller: 'MyProfileCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('MyProfileCtrl', MyProfileCtrl);

    MyProfileCtrl.$inject = ['cuUser', 'api', '$location', '$sce'];
    function MyProfileCtrl(cuUser, api, $location, $sce) {
        var vm = this;
        vm.profile = {};
        vm.company = {};

        vm.toggleTab = toggleTab;
        vm.accountTab = false;
        vm.companyTab = false;
        vm.subscriptionTab = false;
        vm.newsletterTab = false;
        vm.goUpdate = goUpdate;
        vm.trustAsHtml = $sce.trustAsHtml;

        vm.memberId = cuUser.memberId();
        if (!angular.isUndefined(vm.memberId)) {
            api.member.myprofile(vm.memberId)
                .then(function(data) {
                    vm.profile = data.Data.Member;
                    vm.profile.DepartmentName = data.Data.DepartmentName;
                    vm.company = data.Data.Company;
                    vm.newsletters = data.Data.NewsletterPreference;
                });
        } else { // not logged in --> login page
            $location.path('account/11/login/');
        }
        

        function toggleTab(tab) {
            if (tab == 1)
                vm.accountTab = !vm.accountTab;
            else if (tab == 2)
                vm.companyTab = !vm.companyTab;
            else if (tab == 3)
                vm.subscriptionTab = !vm.subscriptionTab;
            else
                vm.newsletterTab = !vm.newsletterTab;
        }

        function goUpdate() {
            $location.path('account/updateProfile');
        }

    }
})();
