(function () {
    'use strict';
    angular.module('creditunionsmobile')
        .controller('HomeCtrl', HomeCtrl);

    HomeCtrl.$inject = ['api', '$routeParams', '$location', '$route', '$rootScope'];
    function HomeCtrl(api, $routeParams, $location, $route, $rootScope) {
        var vm = this;

        //when coming to this page from other pages by link, the slideshow does not fully load due to caching issue.
        //The below code enforces the full reload of the page when redirected from other pages within the site.(MOB-91)

        //if (($rootScope.currUrl.endsWith('mobile/#/') || $rootScope.currUrl.endsWith('mobile/#/#top')) && !$rootScope.prevUrl.endsWith('mobile/#/')) //DEV
        //if (($rootScope.currUrl.endsWith('/#/') || $rootScope.currUrl.endsWith('/#/#top')) && !$rootScope.prevUrl.endsWith('/#/')) // PROD
        //    window.location.reload();

        api.article.getSlideshow().then(function (data) {
            vm.content = data.Data;

        }, function (error) {
            // error handling
        });

        ////////////// bootstrap carousel /////////

        vm.myInterval = 500;
        //vm.noWrapSlides = false;
        vm.active = 1;
        //var slides = vm.slides = [];
        //var currIndex = 0;


        //vm.randomize = function () {
        //    var indexes = generateIndexesArray();
        //    assignNewIndexesToSlides(indexes);
        //};

        //// Randomize logic below

        //function assignNewIndexesToSlides(indexes) {
        //    for (var i = 0, l = slides.length; i < l; i++) {
        //        slides[i].id = indexes.pop();
        //    }
        //}

        //function generateIndexesArray() {
        //    var indexes = [];
        //    for (var i = 0; i < currIndex; ++i) {
        //        indexes[i] = i;
        //    }
        //    return shuffle(indexes);
        //}

        //// http://stackoverflow.com/questions/962802#962890
        //function shuffle(array) {
        //    var tmp, current, top = array.length;

        //    if (top) {
        //        while (--top) {
        //            current = Math.floor(Math.random() * (top + 1));
        //            tmp = array[current];
        //            array[current] = array[top];
        //            array[top] = tmp;
        //        }
        //    }

        //    return array;
        //}
    }

})();