(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('updateProfile', updateProfileDirective);

    function updateProfileDirective() {
        return {
            scope: {},
            templateUrl: 'demo/account/updateProfile.html',
            controller: 'UpdateProfileCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('UpdateProfileCtrl', UpdateProfileCtrl);

    UpdateProfileCtrl.$inject = ['cuUser', 'api', '$location', '$sce'];
    function UpdateProfileCtrl(cuUser, api, $location, $sce) {
        var vm = this;
        vm.profile = {};
        vm.company = {};      
        vm.trustAsHtml = $sce.trustAsHtml;
        vm.update = update;
        vm.Unsubscribe = Unsubscribe;
        vm.Subscribe = Subscribe;
        vm.Unsub = false;
        vm.cdsid = 0;


       
        $.getJSON("http://jsonip.com?callback=?", function (data) {
            //alert("Your ip: " + data.ip);
            vm.profile.Ip = data.ip;
        });
        vm.profile.ActionType = "MemberUpdate";
        vm.profile.PageUrl = "http://www.creditunions.com/demo/edit-account-info/";
        vm.ErrorMessage = '';



        vm.memberId = cuUser.memberId();
        if (!angular.isUndefined(vm.memberId)) {
            api.member.myprofile(vm.memberId).then(function (data) {
                vm.profile.Member = data.Data.Member;
                vm.profile.Member.DepartmentId = data.Data.Member.DepartmentId == null ? "0" : data.Data.Member.DepartmentId.toString();
                vm.cdsid = data.Data.Company.CDSId;
                //vm.profile.DepartmentName = data.Data.DepartmentName;
                vm.profile.NewsletterPreference = data.Data.NewsletterPreference;
                PopulateDepartment();
            });
        }

        function PopulateDepartment() {
            api.selection.departments(vm.cdsid).then(function (data) {
                vm.departments = data.Data;
            });
        }
       
        function Unsubscribe() {
            vm.profile.NewsletterPreference.forEach(function (item) {
                item.Selected = false;
            });
            vm.Unsub = true;
        }
        function Subscribe() {
            vm.Unsub = false;
        }

        function update() {
            api.member.update(vm.profile).then(function (data) {
                if (data.Data == 1 || data.Data == 4) {
                    vm.ErrorMessage = '';
                    $location.path('account/myprofile/');
                }
                else if (data.Data == 2)
                    vm.ErrorMessage = 'Email is currently in use';
                else if (data.Data == 3)
                    vm.ErrorMessage = 'Our email service provider does not allow us to send to general email boxes such as editor@ or contact@. Please use an email account associated with an individual.';
                else {
                    vm.ErrorMessage = 'BlackListed';
                    $location.path('error');
                }
            });
        }



    }
})();
