(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('resetPassword', resetPasswordDirective);

    function resetPasswordDirective() {
        return {
            scope: {},
            templateUrl: 'demo/account/resetPassword.html',
            controller: 'ResetPasswordCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('ResetPasswordCtrl', ResetPasswordCtrl);

    ResetPasswordCtrl.$inject = ['cuUser', 'api'];
    function ResetPasswordCtrl(cuUser, api) {
        var vm = this;
        vm.profile = {};





    }
})();
