(function () {
    'use strict';


    angular.module('creditunionsmobile')
        .controller('LoginCtrl', LoginCtrl);

    LoginCtrl.$inject = ['cuUser', '$routeParams', '$location', '$route', '$rootScope', '$window'];
    function LoginCtrl(cuUser, $routeParams, $location, $route, $rootScope, $window) {
        var vm = this;
        vm.close = close;
        vm.login = login;
        vm.Username = '';
        vm.Password = '';
        vm.RememberMe = true;
        vm.articleId = 0;
        if($routeParams.articleId != null) vm.articleId = parseInt($routeParams.articleId);
        vm.toolId = parseInt($routeParams.toolId);
        vm.error = false;

        if (cuUser.memberId() != null) {
            //redirect to the article/video
            switch (vm.toolId) {
                case 7: $location.path("my-library/");
                    break;
                case 8: $location.path("cusearch/");
                    break;
                case 9: $location.path("bystate/");
                    break;
                case 10: $location.path("executive/");
                    break;
                case 11: $location.path("account/myprofile");
                    break;
                case 16: $location.path("videoId/" + vm.articleId + '/' + cuUser.memberId());
                    break;
                case 17: $location.path("articleId/" + vm.articleId + '/' + cuUser.memberId());
                    break;
                case 25: $location.path("blogId/" + vm.articleId + '/' + cuUser.memberId());
                    break;
                default: $location.path('#/');
            }
            //if (vm.toolId == 17)
            //    $location.path("articleId/" + vm.articleId + '/' + cuUser.memberId());
            //else if (vm.toolId == 25)
            //    $location.path("blogId/" + vm.articleId + '/' + cuUser.memberId());
            //else if (vm.toolId == 16)
            //    $location.path("videoId/" + vm.articleId + '/' + cuUser.memberId());
            //else if (vm.toolId == 7)
            //    $location.path("my-library/");
            //else if (vm.toolId == 8)
            //    $location.path("cusearch/");
            //else if (vm.toolId == 9)
            //    $location.path("bystate/");
            //else if (vm.toolId == 10)
            //    $location.path("executive/");
            //else if (vm.toolId == 11)
            //    $location.path("account/myprofile");
            //else
            //    $location.path('#/');

        }

        function login() {
            //console.log(vm);

            cuUser.validateUser(vm).then(function (data) {
                if (data == 0) {
                    //invalid login --> error message
                    vm.error = true;
                }
                else {
                    $window.location.reload(); // reload the entire page to change the login image to logout image in the header

                    //redirect to the article/video
                    //if (vm.toolId == 17)
                    //    $location.path("articleId/" + vm.articleId + '/' + data);
                    //else if (vm.toolId == 25)
                    //    $location.path("blogId/" + vm.articleId + '/' + data);
                    //else if (vm.toolId == 16)
                    //    $location.path("videoId/" + vm.articleId + '/' + data);
                    //else if(vm.toolId == 8)
                    //    $location.path("cusearch/");
                    //else if (vm.toolId == 9)
                    //    $location.path("bystate/");
                    //else if (vm.toolId == 10)
                    //    $location.path("executive/");
                    //else if (vm.toolId == 11)
                    //    $location.path("account/myprofile");
                    //else
                    //    $location.path('#/');
                }
            }, function (error) {
                // error handling
                //vm.content = "error";
            });
        }

    }
})();
