(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .filter('articleTypeFilter', articleTypeFilter);

    function articleTypeFilter() {
        return function (value) {
            var returnValue;
            switch (value) {
                case 16: returnValue = 'Video';
                    break;
                case 17: returnValue = 'Article';
                    break;
                case 25: returnValue = 'Blog';
                    break;
                default: returnValue = 'No such type';
            }
            return returnValue;
        }
    }

    angular.module('creditunionsmobile')
        .controller('ModalInstanceCtrl', ['api', 'toastr', '$uibModalInstance', 'memberId', 'item',
        function (api, toastr, $uibModalInstance, memberId, item) {
            var vmModal = this;
            vmModal.item = item;

            vmModal.ok = function () {
                var data = {
                    MemberId: memberId,
                    ContentId: item.ArticleId,
                    ToolId: item.ToolId
                };
                api.member.Bookmark(data).then(function (result) {
                    // null object for removing bookmark
                    result.Data;
                    $uibModalInstance.close();
                }, function (error) {
                    // error handling
                    vmModal.content = "error: " + error;
                });
            };

            vmModal.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
    
    angular.module('creditunionsmobile')
        .controller('ClientLibraryCtrl', ClientLibraryCtrl);

    ClientLibraryCtrl.$inject = ['api', '$location', 'cuUser', 'toolId', '$sce', '$anchorScroll', '$filter', 'orderByFilter', '$uibModal', 'toastr'];
    function ClientLibraryCtrl(api, $location, cuUser, toolId, $sce, $anchorScroll, $filter, orderBy, $uibModal, toastr) {
        var vm = this;
        vm.totalItems;
        vm.goToArticle = goToArticle;
        vm.selectPage = selectPage;
        vm.pageNumber = 1;
        vm.memberId = cuUser.memberId() > 0 ? cuUser.memberId() : null;
        vm.show = true;
        vm.url = '';
        selectPage(vm.pageNumber);

        vm.emptyLibText = "There's nothing here :(";

        // Modal
        vm.animationsEnabled = true;
        vm.showConfirm = showConfirm;

        vm.status = {
            isopen: false
        };

        vm.options = [
          "Date Saved",
          "Author",
          "Title",
        ];

        vm.toggleDropdown = function ($event) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.status.isopen = !vm.status.isopen;
            console.log('toggle');
            console.log(vm.status.isopen);
        };
        
        vm.OnChange = function (event) {
            if (!vm.contentTop.length) {
                vm.emptyLibText = "Nothing to sort too!";
                return;
            }
            switch (vm.selectedOption) {
                
                case 'Date Saved': vm.contentTop = orderBy(vm.contentTop, 'DateSaved', true);
                    break;
                case 'Author': vm.contentTop = orderBy(vm.contentTop, 'Author');
                    break;
                case 'Title': vm.contentTop = orderBy(vm.contentTop, 'Headline');
                    break;
                default: vm.contentTop = orderBy(vm.contentTop, 'DateSaved', true);
                    console.log('Nothing selected');
            }
        }

        vm.showCustomToast = function (item, undo) {
            var quote, theme, action, actionQuote;
            if (undo) {
                quote = 'Undo Removal';
                theme = 'toast-bookmark';
                action = '';
            }
            else {
                quote = 'Removed "' + item.Headline + '" from Library!';
                theme = 'toast-remove-bookmark';
                action = UndoRemoval;
                actionQuote = 'Tap to Undo';
            }

            toastr.info(actionQuote, quote, {
                extraData: item,
                iconClass: theme,
                onTap: action
            });
        };

        api.site.url().then(function (data) {
            vm.url = data.Data;

        }, function (error) {

        });

        

        //--------------- BEGIN canonical tag ----------------------
        //var metaTag = document.createElement('link');
        //metaTag.rel = "canonical";
        //metaTag.href = "http://www.creditunions.com/articlelist";
        //document.getElementsByTagName('head')[0].appendChild(metaTag);
        //--------------- END canonical tag ----------------------
        function goToArticle(articleItem) {
            vm.memberId = cuUser.memberId();
            if ((articleItem
                    .ContentType ===
                    'E' ||
                    articleItem.ContentType === 'L') &&
                angular.isUndefined(vm.memberId)) {
                $location.path("account/" + articleItem.ArticleId + '/login/' + articleItem.ToolId);
            } else if (articleItem.CustomUrl !== null) {
                if (articleItem.ToolId === toolId.Press)
                    $location.path(articleItem.CustomUrl);
                else
                    $location.path(articleItem.ArticleId + articleItem.CustomUrl);
            }
        }
        
        function selectPage(pageNum) {
            api.member.getLibraryContents(vm.memberId).then(function (data) {
                var results = data.Data;
                // remove videos from Client Library Content
                results = results.filter(function (el) {
                    return el.ToolId !== 16
                });

                angular.forEach(results, function (value, key) {
                    value.DateSaved = new Date(value.CreateDate).getTime();
                    value.ArticleType = $filter('articleTypeFilter')(value.ToolId);
                });
                vm.contentTop = results;
                // Keeping it as comments in case they want ads in library
                //vm.contentTop = results.slice(0, 5);
                //vm.contentBottom = results.slice(5, 10);

                //vm.showBottom = vm.contentBottom.length > 0;
                vm.totalItems = data.Data.length;
                vm.pageNumber = data.Data.CurrentPage;
                vm.show = true;
                vm.contentTop = orderBy(vm.contentTop, 'DateSaved', true);
                //$location.hash('top');
                $anchorScroll();
            }, function (error) {
                // error handling
                vm.content = "error";
            });
        }

        function UndoRemoval(toastInfo) {
            var item = toastInfo.scope.extraData;
            var data = {
                MemberId: vm.memberId,
                ContentId: item.ArticleId,
                ToolId: item.ToolId
            };
            api.member.Bookmark(data).then(function (result) {
                // null object for removing bookmark
                result.Data;
                vm.showCustomToast(item, true);
                selectPage(1);
            }, function (error) {
                // error handling
                vm.content = "error: " + error;
            });
        }

        function showConfirm(event, item, size, parentSelector) {
            var parentElem = parentSelector ?
              angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
            var modalInstance = $uibModal.open({
                animation: vm.animationsEnabled,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'myModalContent.html',
                controller: 'ModalInstanceCtrl',
                controllerAs: 'vmModal',
                size: size,
                appendTo: parentElem,
                resolve: {
                    memberId: function () {
                        return vm.memberId;
                    },
                    item: function () {
                        return item;
                    }
                }
            }).result.then(function () {
                vm.showCustomToast(item);
                selectPage(1);
            }, function () {
                //close callback
            });

            // prevent propagation of goToArticle
            event.stopPropagation();
        }
    }
})();
