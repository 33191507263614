/* eslint no-alert: 0 */
(function () {
    'use strict';

    //
    // Here is how to define your module
    // has dependent on mobile-angular-ui
    //
    var app = angular.module('creditunionsmobile', [
      'ngRoute',
      'mobile-angular-ui',
      'restangular',
      'ngCookies',
      // touch/drag feature: this is from 'mobile-angular-ui.gestures.js'.
      // This is intended to provide a flexible, integrated and and
      // easy to use alternative to other 3rd party libs like hammer.js, with the
      // final pourpose to integrate gestures into default ui interactions like
      // opening sidebars, turning switches on/off ..
      'mobile-angular-ui.gestures',
      'ui.bootstrap',
      'ngDfp',
      //'ngMaterial',
      'ngAnimate',
      'ngAria',
      'ngMessages',
      'toastr',
      'angulartics',
      'angulartics.google.analytics',
      // 'ngFileUpload'
    ]);

    app.run(['$transform', '$rootScope', '$location', '$window', 'api',  function ($transform, $rootScope, $location, $window, api) {
        api.config.getConfig().then(function (data) {
            if (data.Data != null) {
                if (data.Data["SiteInMaintenanceMode"])
                    window.location.href = '/mobile/maintenance.html';
            }
        });

        window.$transform = $transform;
        //-------------- BEGIN GOOGLE ANALYTICS ------------------------
        $window.ga('create', 'UA-416148-4', 'auto');

        $rootScope.$on('$locationChangeStart', function (event, current, previous) {
            //the values are assigned because the home page slideshow does not load (cached).  
            //in homeController, this value is examined and does full page reload. (MOB-91)
            $rootScope.prevUrl = previous;
            $rootScope.currUrl = current;
        });

        // track pageview on state change
        $rootScope.$on('$routeChangeSuccess', function (event, currentRoute, previousRoute) {
            $window.ga('send', 'pageview', $location.path());
            //$rootScope.title = currentRoute.title;
        });
        //-------------- END GOOGLE ANALYTICS ------------------------

        //detecting the device type.  If desktop --> redirect to www.creditunions.com
        //if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test($window.navigator.userAgent)) {
        //    // some code..
        //} else {
        //    //var currLoation = $location.path();
        //    var pressArray = $location.path().split("/press-center/article/");
        //    var articleArray = $location.path().split("/articles/");
        //    var blogArray = $location.path().split("/blogs/");
        //    var desktopRedirect = "http://www.creditunions.com";

        //    //http://tiger/mobile/#/search/13708/detail/Apple_Fairfax_VA
        //    //http://www.creditunions.com/analyze/profile/Apple/
        //    if (pressArray.length > 1) {
        //        desktopRedirect += "/press-center/article/" + pressArray[1];
        //    }
        //    else if (articleArray.length > 1) {
        //        desktopRedirect += "/articles/" + articleArray[1];
        //    }
        //    else if (blogArray.length > 1) {
        //        desktopRedirect += "/blogs/" + blogArray[1];
        //    }
        //    else if ($location.path().indexOf("/search/")) {
        //        desktopRedirect += "/analyze/";
        //    }

        //    $window.location.href = desktopRedirect;
        //}

    }]);

    //
    // Configuring Theme for angular material
    //
    //app.config(['$mdThemingProvider', function ($mdThemingProvider) {
    //    $mdThemingProvider.theme('success-toast').toastSuccess;
    //    $mdThemingProvider.theme('error-toast').toastError;
    //}])

    app.config(['toastrConfig', function (toastrConfig) {
        angular.extend(toastrConfig, {
            autoDismiss: true,
            containerId: 'toast-container',
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning',
                //bookmark: 'toast-bookmark',
            },
            maxOpened: 0,
            newestOnTop: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: false,
            preventOpenDuplicates: true,
            target: 'body',
            timeOut: 2000,
        });
    }]);

    //
    // You can configure ngRoute as always, but to take advantage of SharedState location
    // feature (i.e. close sidebar on backbutton) you should setup 'reloadOnSearch: false'
    // in order to avoid unwanted routing.
    //
    app.config(['$routeProvider', 'RestangularProvider', 'DoubleClickProvider', function ($routeProvider, RestangularProvider, DoubleClickProvider) {
        $routeProvider.when('/', { templateUrl: 'home.html', reloadOnSearch: false, controller: 'HomeCtrl', controllerAs: 'vm' })
            //.when('/:reloaded', { templateUrl: 'home.html', reloadOnSearch: false, controller: 'HomeCtrl', controllerAs: 'vm' })
                .when('/account/:articleId/login/:toolId', { templateUrl: 'account/login.html', reloadOnSearch: false, controller: 'LoginCtrl', controllerAs: 'vm' })
                .when('/account/login/', { templateUrl: 'account/login.html', reloadOnSearch: false, controller: 'LoginCtrl', controllerAs: 'vm', title: 'login' })
                .when('/account/:pageId/login/', { templateUrl: 'account/login.html', reloadOnSearch: false, controller: 'LoginCtrl', controllerAs: 'vm' })
                .when('/account/myprofile/', { templateUrl: 'account/myProfile.html', reloadOnSearch: false, controller: 'MyProfileCtrl', controllerAs: 'vm' })
                .when('/account/registerSuccess/', { templateUrl: 'account/registerSuccess.html', reloadOnSearch: false })
                .when('/account/updateProfile/', { templateUrl: 'account/updateProfile.html', reloadOnSearch: false, controller: 'UpdateProfileCtrl', controllerAs: 'vm' })
                .when('/account/resetPassword/', { templateUrl: 'account/resetPassword.html', reloadOnSearch: false, controller: 'ResetPasswordCtrl', controllerAs: 'vm' })

                //redirects from desktop site link
                .when('/articles/:title/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/articles/:title/:title2', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/articles/:title/:title2/:title3', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/:id/articles/:title/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/articles/:title/:title2/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/articles/:title/:title2/:title3/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })

                .when('/read/:url/', { templateUrl: 'read/page.html', reloadOnSearch: false, controller: 'PageCtrl', controllerAs: 'vm' })
                .when('/ideas-in-action/:url/', { templateUrl: 'read/page.html', reloadOnSearch: false, controller: 'PageCtrl', controllerAs: 'vm' })
                .when('/ideas-in-action/', { templateUrl: 'read/page.html', reloadOnSearch: false, controller: 'PageCtrl', controllerAs: 'vm' })
                //.when(':id/page/:title/', { templateUrl: 'content/page.html', reloadOnSearch: false, controller: 'PageCtrl', controllerAs: 'vm' })

                .when('/articleId/:id/:memberId', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/blogs/:title', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/blogs/:title/:title2', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/blogs/:title/:title2/:title3', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/:id/blogs/:title/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/blogs/:title/:title2/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/blogs/:title/:title2/:title3/', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/blogId/:id/:memberId', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })
                .when('/analyze/profile/:title', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'RedirectCtrl', controllerAs: 'vm' })

                .when('/:id/article/:title', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/blog/:title', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/video/:title', { templateUrl: 'content/video.html', reloadOnSearch: false, controller: 'VideoCtrl', controllerAs: 'vm' })
                .when('/read/', { templateUrl: 'read/read.html', reloadOnSearch: false, controller: 'ReadCtrl', controllerAs: 'vm' })

                .when('/my-library/', { templateUrl: 'account/clientLibrary.html', reloadOnSearch: false, controller: 'ClientLibraryCtrl', controllerAs: 'vm' })
                .when('/:id/company/:name', { templateUrl: 'read/readSponsor.html', reloadOnSearch: false, controller: 'ReadSponsorCtrl', controllerAs: 'vm' })
                .when('/press-center/article/:id/:title', { templateUrl: 'content/article.html', reloadOnSearch: false, controller: 'ArticleCtrl', controllerAs: 'vm' })
                .when('/:id/authors/:name', { templateUrl: 'content/author.html', reloadOnSearch: false, controller: 'AuthorCtrl', controllerAs: 'vm' })

                .when('/cusearch/', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })
                .when('/bystate/', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })
                .when('/executive/', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })
                .when('/cusearch/:name', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })
                .when('/bystate/:name/:state', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })
                .when('/executive/:name/:state', { templateUrl: 'search/searchCu.html', reloadOnSearch: false, controller: 'SearchCtrl', controllerAs: 'vm' })

                .when('/search/:id/detail/:name', { templateUrl: 'search/cuDetail.html', reloadOnSearch: false, controller: 'CuDetailCtrl', controllerAs: 'vm' })
                .when('/search/result', { templateUrl: 'search/siteSearchResult.html', reloadOnSearch: false, controller: 'SiteSearchResultCtrl', controllerAs: 'vm' })

                .when('/register', { templateUrl: 'account/register.html', reloadOnSearch: false, controller: 'RegisterCtrl', controllerAs: 'vm' })
                .when('/about', { templateUrl: 'about.html', reloadOnSearch: false })

                .when('/:id/email/:toolId', { templateUrl: 'email/email.html', reloadOnSearch: false, controller: 'EmailCtrl', controllerAs: 'vm' })
                .when('/:id/confirmation', { templateUrl: 'email/confirmation.html', reloadOnSearch: false, controller: 'ConfirmCtrl', controllerAs: 'vm' })

                .when('/error', { templateUrl: 'error.html', reloadOnSearch: false })
                //.when('/scroll/', { templateUrl: 'scroll.html', reloadOnSearch: false })
                .otherwise({ redirectTo: '/' });



        //RestangularProvider.setBaseUrl('http://localhost:60604/');
        //RestangularProvider.setBaseUrl('http://tiger:8082');
        RestangularProvider.setBaseUrl('https://callahanws.com');
        //RestangularProvider.setBaseUrl('http://10.0.0.100:8081');
        RestangularProvider.setDefaultHeaders({
            'Accept': 'application/json, text/plain, */*',
            'Access-Control-Allow-Headers': 'Accept, X-Requested-With',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
        });
        /*-------------- BEGIN google ad --------------*/
        // Set Single Request has to be called first to initialize all ad items at once.
        DoubleClickProvider.setSingleRequest(true);
        DoubleClickProvider.collapseEmptyDivs(true);
        DoubleClickProvider.defineSlot('/139974188/Mobile_Footer', [300, 250], 'div-gpt-ad-1498825267484-0')
            .defineSlot('/139974188/Mobile_Middle_Articles', [300, 250], 'div-gpt-ad-1544193749328-0')
            .defineSlot('/139974188/Mobile_Middle_Blogs', [300, 250], 'div-gpt-ad-1544193909570-0')
            .defineSlot('/139974188/Mobile_Middle_PR', [300, 250], 'div-gpt-ad-1544193961733-0')
            .defineSlot('/139974188/Mobile_Hamburger', [300, 250], 'div-gpt-ad-1498825096988-0');
        //DoubleClickProvider.setCentering(true);
        //DoubleClickProvider.setRefreshInterval(2000);
        /*-------------- END google ad --------------*/
    }]);

    //
    // `$touch example`
    //

    app.directive('toucharea', ['$touch', function ($touch) {
        // Runs during compile
        return {
            restrict: 'C',
            link: function ($scope, elem) {
                $scope.touch = null;
                $touch.bind(elem, {
                    start: function (touch) {
                        $scope.containerRect = elem[0].getBoundingClientRect();
                        $scope.touch = touch;
                        $scope.$apply();
                    },

                    cancel: function (touch) {
                        $scope.touch = touch;
                        $scope.$apply();
                    },

                    move: function (touch) {
                        $scope.touch = touch;
                        $scope.$apply();
                    },

                    end: function (touch) {
                        $scope.touch = touch;
                        $scope.$apply();
                    }
                });
            }
        };
    }]);

    //
    // `$drag` example: drag to dismiss
    //
    app.directive('dragToDismiss', function ($drag, $parse, $timeout) {
        return {
            restrict: 'A',
            compile: function (elem, attrs) {
                var dismissFn = $parse(attrs.dragToDismiss);
                return function (scope, elem) {
                    var dismiss = false;

                    $drag.bind(elem, {
                        transform: $drag.TRANSLATE_RIGHT,
                        move: function (drag) {
                            if (drag.distanceX >= drag.rect.width / 4) {
                                dismiss = true;
                                elem.addClass('dismiss');
                            } else {
                                dismiss = false;
                                elem.removeClass('dismiss');
                            }
                        },
                        cancel: function () {
                            elem.removeClass('dismiss');
                        },
                        end: function (drag) {
                            if (dismiss) {
                                elem.addClass('dismitted');
                                $timeout(function () {
                                    scope.$apply(function () {
                                        dismissFn(scope);
                                    });
                                }, 300);
                            } else {
                                drag.reset();
                            }
                        }
                    });
                };
            }
        };
    });

    //
    // Another `$drag` usage example: this is how you could create
    // a touch enabled "deck of cards" carousel. See `carousel.html` for markup.
    //
    app.directive('carousel', function () {
        return {
            restrict: 'C',
            scope: {},
            controller: function () {
                this.itemCount = 0;
                this.activeItem = null;

                this.addItem = function () {
                    var newId = this.itemCount++;
                    this.activeItem = this.itemCount === 1 ? newId : this.activeItem;
                    return newId;
                };

                this.next = function () {
                    this.activeItem = this.activeItem || 0;
                    this.activeItem = this.activeItem === this.itemCount - 1 ? 0 : this.activeItem + 1;
                };

                this.prev = function () {
                    this.activeItem = this.activeItem || 0;
                    this.activeItem = this.activeItem === 0 ? this.itemCount - 1 : this.activeItem - 1;
                };
            }
        };
    });

    app.directive('carouselItem', function ($drag) {
        return {
            restrict: 'C',
            require: '^carousel',
            scope: {},
            transclude: true,
            template: '<div class="item"><div ng-transclude></div></div>',
            link: function (scope, elem, attrs, carousel) {
                scope.carousel = carousel;
                var id = carousel.addItem();

                var zIndex = function () {
                    var res = 0;
                    if (id === carousel.activeItem) {
                        res = 2000;
                    } else if (carousel.activeItem < id) {
                        res = 2000 - (id - carousel.activeItem);
                    } else {
                        res = 2000 - (carousel.itemCount - 1 - carousel.activeItem + id);
                    }
                    return res;
                };

                scope.$watch(function () {
                    return carousel.activeItem;
                }, function () {
                    elem[0].style.zIndex = zIndex();
                });

                $drag.bind(elem, {
                    //
                    // This is an example of custom transform function
                    //
                    transform: function (element, transform, touch) {
                        //
                        // use translate both as basis for the new transform:
                        //
                        var t = $drag.TRANSLATE_BOTH(element, transform, touch);

                        //
                        // Add rotation:
                        //
                        var Dx = touch.distanceX;
                        var t0 = touch.startTransform;
                        var sign = Dx < 0 ? -1 : 1;
                        var angle = sign * Math.min((Math.abs(Dx) / 700) * 30, 30);

                        t.rotateZ = angle + (Math.round(t0.rotateZ));

                        return t;
                    },
                    move: function (drag) {
                        if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                            elem.addClass('dismiss');
                        } else {
                            elem.removeClass('dismiss');
                        }
                    },
                    cancel: function () {
                        elem.removeClass('dismiss');
                    },
                    end: function (drag) {
                        elem.removeClass('dismiss');
                        if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                            scope.$apply(function () {
                                carousel.next();
                            });
                        }
                        drag.reset();
                    }
                });
            }
        };
    });

    app.directive('dragMe', ['$drag', function ($drag) {
        return {
            controller: function ($scope, $element) {
                $drag.bind($element,
                  {
                      //
                      // Here you can see how to limit movement
                      // to an element
                      //
                      transform: $drag.TRANSLATE_INSIDE($element.parent()),
                      end: function (drag) {
                          // go back to initial position
                          drag.reset();
                      }
                  },
                  { // release touch when movement is outside bounduaries
                      sensitiveArea: $element.parent()
                  }
                );
            }
        };
    }]);

    //
    // For this trivial demo we have just a unique MainController
    // for everything
    //
    //app.controller('MainController', function($rootScope, $scope) {
    app.controller('MainController', ['$rootScope', '$scope', '$uibModal', 'cuUser', '$location', function ($rootScope, $scope, $uibModal, cuUser, $location) {
        $scope.loggedIn = cuUser.memberId() === null ? false : true;
        $scope.swiped = function (direction) {
            //alert('Swiped ' + direction);
        };
        $scope.openLogin = function () {
            $location.path("account/login/");
        }
        $scope.logout = function () {
            $scope.loggedIn = false;
            cuUser.logout();
            $location.path('#/');
        };

        // User agent displayed in home page
        $scope.userAgent = navigator.userAgent;

        // Needed for the loading screen
        $rootScope.$on('$routeChangeStart', function () {
            $scope.loading = true;
        });

        $rootScope.$on('$routeChangeSuccess', function () {
            $scope.loading = false;
        });

        //
        // 'Scroll' screen
        //
        var scrollItems = [];

        for (var i = 1; i <= 100; i++) {
            scrollItems.push('Item ' + i);
        }

        $scope.scrollItems = scrollItems;

        $scope.bottomReached = function () {
            //alert('Congrats you scrolled to the end of the list!');
        };


        //
        // 'Forms' screen
        //
        $scope.rememberMe = true;
        $scope.email = 'me@example.com';

        $scope.login = function () {
            alert('You submitted the login form');
        };

        //
        // 'Drag' screen
        //
        $scope.notices = [];

        for (var j = 0; j < 10; j++) {
            $scope.notices.push({ icon: 'envelope', message: 'Notice ' + (j + 1) });
        }

        $scope.deleteNotice = function (notice) {
            var index = $scope.notices.indexOf(notice);
            if (index > -1) {
                $scope.notices.splice(index, 1);
            }
        };
    }]);

    //----------------------------------------------------------------------------------
    // services
    //----------------------------------------------------------------------------------


    app.service('api', ['Restangular', function (Restangular) {
        //var RestangularWithCache = function () {
        //    return Restangular.withConfig(function (RestangularConfigurer) {
        //        RestangularConfigurer.setDefaultHttpFields({ cache: true, useXDomain: true });
        //    });
        //};
        return {
            config: {
                getConfig: function() {
                    return Restangular.one('Config').one('GetConfig').get();
                }
            },
            member: {
                validateUser: function (memParam) {
                    return Restangular.one('Cu/Member').one("ValidateUser").customPOST(memParam);
                },
                validateSession: function (id) {
                    return Restangular.one('Cu/Member', id).one("ValidateSession").get();
                },
                myprofile: function (id) {
                    return Restangular.one('Cu/Member', id).one("MyProfile").get();
                },
                register: function (query) {
                    return Restangular.one('Cu/Member').one('Register').customPOST(query);
                },
                logout: function (id) {
                    return Restangular.one('Cu/Member', id).one("Logout").get();
                },
                update: function (query) {
                    return Restangular.one('Cu/Member').one('Profile').customPOST(query);
                },
                getLibraryContents: function (id, memberId) {
                    return Restangular.one('Cu/Member', id).one("GetLibraryContents").get();
                },
                Bookmark: function (data) {
                    return Restangular.one('Cu/Member').one("Bookmark").customPOST(data);
                },
                isBookmarked: function (data) {
                    return Restangular.one('Cu/Member').one("isBookmarked").customPOST(data);
                },
                getAccessDetails: function (memberId) {
                    return Restangular.one('Cu/Member', memberId).one("GetAccessDetails").get();
                }
            },
            blog: {
                getById: function (id, memberId) {
                    return Restangular.one('Blog', id).one("GetBlogById", memberId).get();
                }
            },
            author: {
                get: function (id, param) {
                    return Restangular.one('Author', id).one('GetAuthor').get(param);
                }
            },
            article: {
                getByCategory: function (id, pageNum) {
                    return Restangular.one('Read', id).one("GetByCategory", pageNum).get();
                },
                getById: function (id, memberId) {
                    return Restangular.one('Read', id).one("GetArticleById", memberId).get();
                },
                getThisWeek: function () {
                    return Restangular.one('Read').one("GetThisWeek").get();
                },
                redirect: function (param) {
                    return Restangular.one('Read').one('GetIdByUrl').get(param);
                },
                getSlideshow: function () {
                    return Restangular.one('Slideshow').get();
                },
                email: function (data) {
                    return Restangular.one('Read').one('Email').customPOST(data);
                },
                getEmail: function (id) {
                    return Restangular.one('Read', id).one('Email').get();
                },
                getByCompany: function (id) {
                    return Restangular.one('Read', id).one("ByCompany").get();
                }
            },
            page: {
                getPageByUrl: function (param) {
                    return Restangular.one('Page').one("GetPageByUrl").get(param);
                }
            },
            video: {
                getVideoByCategory: function (pageNum) {
                    return Restangular.one('Video').one("GetVideoList", pageNum).get();
                },
                getById: function (id, memberId) {
                    return Restangular.one('Video', id).one("GetVideoById", memberId).get();
                }
            },
            city: {
                getCitiesByState: function (stateId) {
                    return Restangular.one('City', stateId).one("GetCitiesByState").get();
                },
                getStates: function () {
                    return Restangular.one('City').one("GetStates").get();
                }
            },
            search: {
                cu: function (cuParam) {
                    //var temp = Restangular.one('CreditUnion').one('CreditUnionSearch').get(cuParam);
                    return Restangular.one('CreditUnion').one('CreditUnionSearch').get(cuParam);
                },
                executive: function (cuParam) {
                    return Restangular.one('CreditUnion').one('ExecutiveSearch').get(cuParam);
                },
                siteSearch: function (cuParam) {
                    return Restangular.one('SiteSearch').get(cuParam);
                }
            },
            creditunion: {
                profile: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('CompanyProfile', memberId).get();
                },
                financial: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('Financial', memberId).get();
                },
                searchAndAnalyze: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('SearchAndAnalyze', memberId).get();
                },
                scorecard: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('Scorecard', memberId).get();
                },
                technologypartner: function (id, memberId) {
                    return Restangular.one('Peer', id).one('VendorList', memberId).get();
                },
                branch: function (id, memberId) {
                    return Restangular.one('Branch', id).one('BranchList', memberId).get();
                },
                cycleName: function (id) {
                    return Restangular.one('Peer', id).one('CycleName').get();
                }
            },
            press: {
                getById: function (id, memberId) {
                    return Restangular.one('Press', id).one("GetPressById", memberId).get();
                }
            },
            selection: {
                mailingOptions: function (id) {
                    return Restangular.one('Selection', id).one("MailingOptions").get();
                },
                departments: function (id) {
                    return Restangular.one('Selection', id).one("Departments").get();
                }
            },
            error: {
                insert: function (errorParam) {
                    return Restangular.one('Error', id).one("Insert", memberId).customPOST(errorParam);
                }
            },
            site: {
                url: function () {
                    return Restangular.one("Site").one("Url").get();
                }
            }
        }
    }]);

    //-------------------------------------------------------------------------------
    // there are 2 ways of storing user session below
    // option 1 storing in the DB
    // option 2 storing in cookies
    // #1 gives more secure authentication but traveling to the server costs more
    //-------------------------------------------------------------------------------
    app.factory('cuUser', ['api', '$cookies', function (api, $cookies) {
        var fullName = null;
        var memberId = $cookies.get("CuMobileMemberId");
        var lastAccessedDate = null;
        var sessionEndTime = null;
        var subscriptionType = '';
        return {
            sessionEndTime: function () {
                return this.sessionEndTime;
            },
            memberId: function () {
                //option 1 -- travel to the server to verify the user session
                //return api.member.validateSession(memberId).then(function (data) {
                //    if (data.Data != null && !angular.isUndefined(data.Data)) {
                //        sessionEndTime = data.Data.SessionEndTime;
                //        memberId = data.Data.MemberId;
                //        return memberId;
                //    }
                //    return null;
                //}, function (error) {
                //    return null;

                //});

                //option 2 -- use cookie to store the user session
                //var today = new Date();
                //var expirationDate = new Date();
                //expirationDate.setDate(today.getDate() + 15);
                //$cookies.put("CuMobileMemberId", memberId, { expires: expirationDate });
                memberId = $cookies.get("CuMobileMemberId");
                var today = new Date();
                if ((lastAccessedDate === null || lastAccessedDate < today) && memberId != null) {
                    api.member.validateSession(memberId).then(function (data) {
                        if (data.Data != null && !angular.isUndefined(data.Data)) {
                            memberId = data.Data.MemberId;
                            sessionEndTime = data.Data.SessionEndTime;
                            lastAccessedDate = data.Data.LastAccessedDate;
                            subscriptionType = data.Data.SubscriptionType;
                        }
                        else return 0;

                    }, function (error) {
                        // error handling

                    });
                }
                return memberId;
            },
            subscriptionType: function () {
                return subscriptionType;
            },
            //getCurrentUser: function () {
            //    var self = this;
            //    //return api.userInformation().then(function (data) {
            //    //    self.saveUserInfo(data);
            //    //});
            //    return this.memberId;
            //},
            validateUser: function (info) {
                return api.member.validateUser(info).then(function (data) {
                    if (data.Data != null && !angular.isUndefined(data.Data)) {
                        memberId = data.Data.MemberId;
                        sessionEndTime = data.Data.SessionEndTime;
                        lastAccessedDate = data.Data.LastAccessedDate;
                        subscriptionType = data.Data.SubscriptionType;

                        //store the memberId in cookies
                        var today = new Date();
                        var expirationDate = new Date();
                        if (info.RememberMe)
                            expirationDate.setDate(today.getDate() + 15);
                        else
                            expirationDate.setDate(today.getMinute() + 30);
                        $cookies.put("CuMobileMemberId", memberId, { expires: expirationDate });

                        return memberId;
                    }
                    else return 0;

                }, function (error) {
                    // error handling

                });
            },
            validateSession: function (memberId) {
                return api.member.validateSession(memberId).then(function (data) {
                    if (data.Data !== null && !angular.isUndefined(data.Data)) {
                        sessionEndTime = data.Data.SessionEndTime;
                        lastAccessedDate = data.Data.LastAccessedDate;
                        return true;
                    }
                    return false;
                }, function (error) {
                    return false;

                });
            },
            logout: function () {
                //option 1 removing from the DB
                api.member.logout(memberId);


                //option 2 removing from cookies
                var today = new Date();
                $cookies.remove("CuMobileMemberId");
                location.reload();
            }
        };
    }]);

    app.service('error', ['api', 'cuUser', function (api, cuUser) {
        return {

            search: function (location, errorMessage) {
                var log = {
                    MemberId: cuUser.memberId(),
                    Location: location,
                    ErrorMessage: errorMessage
                };
                $log.error(location, errorMessage);
                return api.error.insert(log).then(function (data) {
                }, function (error) {
                });
            }
        };
    }]);

    //----------------------------------------------------------------------------------
    // constants
    //----------------------------------------------------------------------------------
    app.constant('pageId', {
        Stragegy: 1,
        Deposits: 2,
        Performance: 3,
        Lending: 4,
        Marketing: 5,
        Operations: 6,
        Videos: 7,
        SearchByCu: 8,
        FindByState: 9,
        ExecutiveSearch: 10,
        Press: 11,
        Career: 12,
        Calendar: 13,
        Guide: 14,
        Report: 15,
        Collections: 16,
        Research: 17,
        Anatomy: 18
    });

    app.constant('toolId', {
        Blog: 25,
        News: 17,
        Video: 16,
        SearchByCu: 8,
        FindByState: 9,
        ExecutiveSearch: 10,
        Press: 30
    });

    app.constant('membershipId', {
        Leadership: 1,
        Exclusive: 2,
        NonMember: 3
    });

    //----------------------------------------------------------------------------------
    // filter
    //----------------------------------------------------------------------------------
    app.filter('telFilter', function () {
        return function (tel) {
            if (!tel) { return ''; }
            //var value = tel.toString().trim().replace(/^\+/, '');

            var value = Number.parseFloat(tel).toFixed();
            if (value.match(/[^0-9]/)) {
                return tel;
            }
            
            var country, city, number;

            switch (value.length) {
                case 10: // +1PPP####### -> C (PPP) ###-####
                    country = 1;
                    city = value.slice(0, 3);
                    number = value.slice(3);
                    break;

                case 11: // +CPPP####### -> CCC (PP) ###-####
                    country = value[0];
                    city = value.slice(1, 4);
                    number = value.slice(4);
                    break;

                case 12: // +CCCPP####### -> CCC (PP) ###-####
                    country = value.slice(0, 3);
                    city = value.slice(3, 5);
                    number = value.slice(5);
                    break;

                default:
                    return tel;
            }

            if (country === 1) {
                country = "";
            }

            number = number.slice(0, 3) + '-' + number.slice(3);

            return (country + " (" + city + ") " + number).trim();
        };
    }).filter('titleCase', function () {
        return function (input) {
            input = input || '';
            return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        };
    });

    app.directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return val != null ? parseInt(val, 10) : null;
                });
                ngModel.$formatters.push(function (val) {
                    return val !== null ? '' + val : null;
                });
            }
        };
    });
})();
