(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('RedirectCtrl', RedirectCtrl);

    // when redirected from login, cuUser still does not contain MemberId.  
    // So the memberId value is passed as a route parameter 


    RedirectCtrl.$inject = [ 'api', '$routeParams', '$location', 'cuUser'];
    function RedirectCtrl(api, $routeParams, $location, cuUser) {
        var vm = this;
        vm.currPath = $location.path();
        vm.Data = {
            ItemType: '',
            Title1: $routeParams.title,
            Title2: '',
            Title3: '',
            Id: 0,
            MemberId: 0
        }
        vm.Data.MemberId = cuUser.memberId() == null ? 0 : cuUser.memberId();
        if (vm.currPath.indexOf('articles/') === 1 || vm.currPath.indexOf('articleId') === 1) vm.Data.ItemType = 'articles';
        else if (vm.currPath.indexOf('blogs/') === 1 || vm.currPath.indexOf('blogId/') === 1) vm.Data.ItemType = 'blogs';
        else if (vm.currPath.indexOf('analyze/profile') === 1) vm.Data.ItemType = 'profile';

        if (vm.currPath.indexOf('articleId') === 1 || vm.currPath.indexOf('blogId') === 1) {
            vm.Data.MemberId = $routeParams.memberId;
            vm.Data.Id = $routeParams.id;

        } else {
            if (!angular.isUndefined($routeParams.title2))
                vm.Data.Title2 = $routeParams.title2;
            if (!angular.isUndefined($routeParams.title3))
                vm.Data.Title3 = $routeParams.title3;
        }
        api.article.redirect(vm.Data).then(function (data) {
            //redirect to mobile site
            vm.result = data.Data;
            if (vm.Data.ItemType == 'articles')
                $location.path("/" + vm.result.ItemId + vm.result.TitleUrl);
            else if(vm.Data.ItemType == 'blogs')
                $location.path("/" + vm.result.ItemId +  vm.result.TitleUrl);
            else if(vm.Data.ItemType == 'profile')
                $location.path("/search/" + vm.result.ItemId + "/detail/" + vm.result.TitleUrl);
            ///search/:id/detail/:name
        }, function (error) {
            // error handling
            vm.content = "error";
        });

    }
})();
