
(function () {
    'use strict';
    var app = angular.module("creditunionsmobile");
    app.directive("articleListDirective", function () {
        return {
            scope: {
                typeId: '='

            },
            templateUrl: 'read/articleList.html',
            controller: 'ArticleListCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('creditunionsmobile')
        .controller('ArticleListCtrl', ArticleListCtrl);

    ArticleListCtrl.$inject = ['api', '$scope', '$location', 'cuUser', 'toolId', '$sce', '$anchorScroll'];

    function ArticleListCtrl(api, $scope, $location, cuUser, toolId, $sce, $anchorScroll) {

        var vm = this;
        vm.goToArticle = goToArticle;
        vm.selectPage = selectPage;
        vm.pageNumber = 1;
        vm.memberId = null;
        vm.show = true;
        vm.url = '';
        selectPage(vm.pageNumber);

        api.site.url().then(function (data) {
            vm.url = data.Data;

        }, function (error) {

        });

        //--------------- BEGIN canonical tag ----------------------
        //var metaTag = document.createElement('link');
        //metaTag.rel = "canonical";
        //metaTag.href = "http://www.creditunions.com/articlelist";
        //document.getElementsByTagName('head')[0].appendChild(metaTag);
        //--------------- END canonical tag ----------------------
        function goToArticle(articleItem) {
            vm.memberId = cuUser.memberId();
            if ((articleItem
                    .ContentType ===
                    'E' ||
                    articleItem.ContentType === 'L') &&
                angular.isUndefined(vm.memberId)) {
                $location.path("account/" + articleItem.ItemId + '/login/' + articleItem.ToolId);
            } else if (articleItem.TitleUrl !== null) {

                //if (articleItem.ToolId === toolId.Blog)
                //    $location.path(articleItem.ItemId + "/blog/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.News)
                //    $location.path(articleItem.ItemId + "/article/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.Video)
                //    $location.path(articleItem.ItemId + "/video/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.Press)
                //    $location.path(articleItem.ItemId + "/press/" + articleItem.TitleUrl);

                if (articleItem.ToolId === toolId.Press)
                    $location.path(articleItem.TitleUrl);
                else
                    $location.path(articleItem.ItemId + articleItem.TitleUrl);

                //if (articleItem.ToolId === toolId.Blog)
                //    $location.path(articleItem.ItemId  + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.News)
                //    $location.path(articleItem.ItemId  + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.Press)
                //    $location.path(articleItem.TitleUrl);
            }
        }

        function selectPage(pageNum) {
            if ($scope.typeId === 1) {
                //home page --> display this week's featured articles
                api.article.getThisWeek().then(function (data) {
                    vm.contentTop = data.Data;
                    vm.totalItems = 0;
                    vm.pageNumber = 0;
                    vm.show = false;
                }, function (error) {
                    // error handling
                    vm.content = "error";
                });
            }
            else {
                api.article.getByCategory($scope.typeId, vm.pageNumber).then(function (data) {
                    vm.contentTop = data.Data.SummaryList.slice(0, 5);
                    vm.contentBottom = data.Data.SummaryList.slice(5, 10);
                    vm.showBottom = vm.contentBottom.length > 0;
                    vm.totalItems = data.Data.TotalItems;
                    vm.pageNumber = data.Data.CurrentPage;
                    vm.show = true;
                    //$location.hash('top');
                    $anchorScroll();
                }, function (error) {
                    // error handling
                    vm.content = "error";
                });
            }
        }
    }
})();