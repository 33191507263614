(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('register', registerDirective);

    function registerDirective() {
        return {
            scope: {},
            templateUrl: 'demo/account/register.html',
            controller: 'RegisterCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('RegisterCtrl', RegisterCtrl);

    RegisterCtrl.$inject = ['api', '$location'];
    function RegisterCtrl(api, $location) {
        var vm = this;
        vm.ok = ok;
        vm.emailCheck = {};
        vm.invalidEmail = false;
        vm.emailTaken = false;
        vm.personalInfo = {
            Firstname: '',
            Lastname: '',
            Email: '',
            Confirmemail: '',
            Password: '',
            Confirmpassword: '',
            AccountType: '1',
            Title: '',
            DepartmentId: '1',
            Newsletter: '3',
            ActionType: 'MemberRegister',
            PageUrl: 'http://www.creditunions.com/demo/register/'
        };
        vm.ErrorMessage = '';
        $.getJSON("http://jsonip.com?callback=?", function (data) {
            //alert("Your ip: " + data.ip);
            vm.personalInfo.Ip = data.ip;
        });

        api.selection.mailingOptions().then(function (data) {
            vm.personalInfo.mailingOptions = data.Data;
        });
        api.selection.departments().then(function (data) {
            vm.departments = data.Data;
        });

        function ok(form) {
            //check if the email has been already registered before
            var temp = vm.personalInfo;
            api.member.register(vm.personalInfo).then(function (data) {
                if (data.Data == 1 || data.Data == 4) {
                    vm.ErrorMessage = '';
                    $location.path('account/registerSuccess/');
                }
                else if (data.Data == 2)
                    vm.ErrorMessage = 'Email is currently in use';
                else if (data.Data == 3)
                    vm.ErrorMessage = 'Our email service provider does not allow us to send to general email boxes such as editor@ or contact@. Please use an email account associated with an individual.';
                else {
                    vm.ErrorMessage = 'BlackListed';
                    $location.path('error');
                }
            });
        }

    }
})();
