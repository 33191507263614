//(function () {
//    angular.module('creditunionsmobile')
//        .directive('footer', footerDirective);

//    function footerDirective() {
//        return {
//            restrict: 'A',
//            scope: {},
//            templateUrl: '<h1>test footer</h1>',
//            controller: 'FooterCtrl',
//            controllerAs: 'footer'
//        };
//    }


//})();

(function () {
    'use strict';
    var app = angular.module("creditunionsmobile");
    app.directive("footerDirective", function () {
        return {
            //template: "<h1>Made by a directive!</h1>"
            templateUrl: "footer.html",
            controller: 'FooterCtrl',
            controllerAs: 'footer'
        };
    });
    angular.module('creditunionsmobile')
        .controller('FooterCtrl', FooterCtrl);

    //FooterCtrl.$inject = ['$anchorScroll'];

    //function FooterCtrl($anchorScroll) {
    function FooterCtrl() {
        var footer = this;
        footer.copyrightYear = new Date();
        //var vm = this;
        //vm.scrollToTop = scrollToTop;

        //function scrollToTop() {
        //    $anchorScroll();
        //}
    }
})();