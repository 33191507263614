(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('read', readDirective);

    function readDirective() {
        return {
            scope: {},
            templateUrl: 'read/read.html',
            controller: 'ReadCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('ReadCtrl', ReadCtrl);

    ReadCtrl.$inject = ['$uibModal', '$scope', '$routeParams'];
    function ReadCtrl($uibModal, $scope, $routeParams) {
        var vm = this;
        vm.activeTab = 1;
        vm.switchTab = switchTab;

        function switchTab(tabId) {
            vm.activeTab = tabId;
        }

        //vm.pageId = $routeParams.pageId
        //if (vm.pageId == 184)
        //    vm.title = "Cooperative Strategy";
        //else if (vm.pageId == 185)
        //    vm.title = "Deposits & Payments";
        //else if (vm.pageId == 186)
        //    vm.title = "Financial Performance";
        //else if (vm.pageId == 172)
        //    vm.title = "Lending";
        //else if (vm.pageId == 200)
        //    vm.title = "Marketing";
        //else if (vm.pageId == 187)
        //    vm.title = "Operations & Tech";
        //else
        //    vm.title = "Videos";
    }
})();
