(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('ConfirmCtrl', ConfirmCtrl);

    ConfirmCtrl.$inject = ['api', '$routeParams', '$window' ];
    function ConfirmCtrl(api, $routeParams, $window) {
        var vm = this;
        vm.id = parseInt($routeParams.id);
        vm.close = close;

        api.article.getEmail(vm.id)
                .then(function (data) {
                    vm.content = data.Data;
                },
                    function (error) {
                        // error handling
                        vm.content = "error";
                    });
        
        function close() {
            $window.close();
        }
        
    }
})();
