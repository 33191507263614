(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('SiteSearchResultCtrl', SiteSearchResultCtrl);

    SiteSearchResultCtrl.$inject = ['api', '$routeParams', 'cuUser', '$location', 'toolId'];
    function SiteSearchResultCtrl(api, $routeParams, cuUser, $location, toolId) {
        var vm = this;
        vm.param = {
            keyword: $routeParams.keyword,
            PageNumber: 1
        }
        vm.activeTab = 1;
        vm.goToArticle = goToArticle;
        vm.selectPage = selectPage;
        vm.redirectAuthor = redirectAuthor;
        vm.redirectCompany = redirectCompany;
        vm.goToPressRelease = goToPressRelease;
        selectPage(vm.pageNumber);

        //--------------------------------------------------------------------------------

        function selectPage(pageNum) {
            vm.content = [];
            vm.param.PageNumber = pageNum;
            api.search.siteSearch(vm.param).then(function (data) {
                vm.content = data.Data.Articles.SummaryList;
                vm.releases = data.Data.PressReleases.SummaryList;
                vm.companies = data.Data.Companies;
                vm.authors = data.Data.Authors;

                vm.totalItems = data.Data.Articles.TotalItems;
                vm.pageNumber = data.Data.Articles.CurrentPage;

                vm.NoAuthors = vm.authors.length == 0 ? true : false;
                vm.NoCompanies = vm.companies.length == 0 ? true : false;
                vm.NoContent = vm.content.length == 0 ? true : false;

            }, function (error) {
                // error handling

            });
        }

        //--------------------------------------------------------------------------------

        function goToArticle(articleItem) {
            vm.memberId = cuUser.memberId();
            if ((articleItem.ContentType == 'E' || articleItem.ContentType == 'L') && angular.isUndefined(vm.memberId)) { //login required
                $location.path("account/" + articleItem.ItemId + '/login/' + articleItem.ToolId);
            }

            else {
                if (articleItem.ToolId == toolId.Blog)
                    $location.path(articleItem.ItemId + articleItem.TitleUrl);
                else if (articleItem.ToolId == toolId.News)
                    $location.path(articleItem.ItemId + articleItem.TitleUrl);
                else if (articleItem.ToolId == toolId.Video)
                    $location.path(articleItem.ItemId  + articleItem.TitleUrl);      
            }
        }

        function goToPressRelease(article) {
            $location.path(article.TitleUrl);
            //$location.path(article.ItemId + "/press-center/" + article.TitleUrl );
        }
        function redirectAuthor(author) {
            $location.path(author.AuthorId + "/authors/" + author.AuthorUrl);
        }
        
        function redirectCompany(company) {
            $location.path("search/" + company.CdsId + "/detail/" + company.CompanyUrl);
        }
    }
})();
