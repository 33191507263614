
(function () {
    'use strict';
    var app = angular.module("creditunionsmobile");
    app.directive("siteSearchDirective", function () {
        return {
            templateUrl: 'search/siteSearch.html',
            controller: 'SiteSearchCtrl',
            controllerAs: 'searchVm'

        };
    });
    angular.module('creditunionsmobile')
        .controller('SiteSearchCtrl', SiteSearchCtrl);

    SiteSearchCtrl.$inject = ['api', '$location', 'cuUser', '$route'];

    function SiteSearchCtrl(api, $location, cuUser, $route) {

        var vm = this;
        vm.search = search;
        vm.searchphrase = '';
        //vm.searching = false;
        vm.search = search;

        function search() {
            $route.reload();
            $location.path("search/result").search({ keyword: vm.searchphrase });
        }
    }
})();