(function () {
    'use strict';
    angular.module('creditunionsmobile')
        .directive('cuDetail', cuDetailDirective);

    function cuDetailDirective() {
        return {
            scope: {},
            templateUrl: 'demo/search/cuDetail.html',
            controller: 'CuDetailCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('CuDetailCtrl', CuDetailCtrl);

    CuDetailCtrl.$inject = ['Restangular', 'api', '$location', '$routeParams', 'cuUser', '$sce'];
    function CuDetailCtrl(Restangular, api, $location, $routeParams, cuUser, $sce) {
        var vm = this;
        vm.path = $location.path();
        vm.cdsId = parseInt($routeParams.id);
        vm.activeTab = 0;
        vm.toggleTab = toggleTab;
        vm.financialTab = false;
        vm.executiveTab = false;
        vm.technologyTab = false;
        vm.scorecardTab = false;
        vm.branchTab = false;
        vm.backToSearch = backToSearch;
        vm.memberId = cuUser.memberId();
        vm.subscriptionType = cuUser.subscriptionType();
        vm.isExclusiveMember = vm.subscriptionType != 3 ? true : false;
        vm.Message = '';

        //enforce login
        vm.loggedin = cuUser.memberId() == null ? false : true;
        if (!vm.loggedin) 
            $location.path("account/0/login/8");
        else
            init();
        

        function init() {
            api.creditunion.profile(vm.cdsId, vm.memberId).then(function (data) {
                if (angular.isUndefined(data.Data.Name)) {
                    // error            
                }
                else {
                    vm.Institution = data.Data;
                    vm.Institution.membercompanyid = data.membercompanyid;
                    if (data.contentSubscriptionStatus == 'LC') 
                        vm.contentSubscriptionStatus = 'Professional';
                    else if (data.contentSubscriptionStatus == 'MC')
                        vm.contentSubscriptionStatus = 'Standard';
                    else if (data.contentSubscriptionStatus == 'EC')
                        vm.contentSubscriptionStatus = 'Basic';
                    else if (data.contentSubscriptionStatus == 'N')
                        vm.contentSubscriptionStatus = 'Complimentary';
                    else
                        vm.contentSubscriptionStatus = 'Off-Menu';               
                }
                 
            }, function (error) {
                // error handling
            });

            api.creditunion.searchAndAnalyze(vm.cdsId, vm.memberId).then(function (data) {
                if (!angular.isUndefined(data.Data.data)) {
                    api.creditunion.cycleName(data.Data.data[0][0]).then(function (dateData) {
                        vm.DataDate = dateData.Data;
                        if (vm.DataDate == '')
                            vm.Message = 'No data';
                    });

                    //vm.Financial = data.Data.data;
                    vm.Financial = {
                        Assets: data.Data.data[0][112],
                        Loans: data.Data.data[0][113],
                        Investments: data.Data.data[0][144],
                        Capital: data.Data.data[0][143],
                        Members: data.Data.data[0][121],
                        Employees: data.Data.data[0][58],
                        ShareGrowth: data.Data.data[0][4] * 100,
                        LoanGrowth: data.Data.data[0][5] * 100,
                        Roa: data.Data.data[0][117] * 100 //169? -- no matching #
                    };
                    if (vm.isExclusiveMember) {
                        //vm.Vendor =
                        //{
                        //    DataProcessor : data.Data.data[0][164],
                        //    CcProcessor : data.Data.data[0][159],
                        //    DebitCardSignature : data.Data.data[0][165],
                        //    DebitCardPin : data.Data.data[0][166],
                        //    PrepaidCard : data.Data.data[0][171],
                        //    OnlineBanking : data.Data.data[0][162],
                        //    FinancialManagement : data.Data.data[0][172],
                        //    BillPayer : data.Data.data[0][161],
                        //    EStatement : data.Data.data[0][160],
                        //    MobileBankingBrowser : data.Data.data[0][173],
                        //    MobileBankingApp : data.Data.data[0][174],
                        //    RemoteDepositMobile : data.Data.data[0][175],
                        //    RemoteDepositHome : data.Data.data[0][141],
                        //    LoanOriginationPlatform : data.Data.data[0][176],
                        //    MortgageOriginationPlatform : data.Data.data[0][177],
                        //    StudentLoanProvider : data.Data.data[0][181],
                        //    Collections : data.Data.data[0][182],
                        //    CallCenter : data.Data.data[0][183],
                        //    CreditCardPortfolio : data.Data.data[0][167],
                        //    Auditor : data.Data.data[0][163],
                        //    ApplePay : data.Data.data[0][187]
                        //};

                        vm.Scorecard = {
                            CdsId: vm.cdsId,

                            //growth
                            Shares: data.Data.data[0][116] * 100,
                            Loans: data.Data.data[0][115] * 100,
                            Assets: data.Data.data[0][114] * 100,
                            Capital: data.Data.data[0][7] * 100,

                            TotalRevenue: data.Data.data[0][9] * 100,
                            OperatingExpenses: data.Data.data[0][10] * 100,
                            LoanLoss: data.Data.data[0][11] * 100,
                            Dividends: data.Data.data[0][12] * 100,
                            NetIncome: data.Data.data[0][13] * 100,

                            //member metrics
                            MemberGrowth: data.Data.data[0][15] * 100,
                            PotentialMembers: data.Data.data[0][16] * 100,
                            SharePerMember: data.Data.data[0][17],
                            AverageShareBalance: data.Data.data[0][18],
                            LoanPerMember: data.Data.data[0][19],
                            AverageLoanBalance: data.Data.data[0][20],
                            AverageMemberRelationship: data.Data.data[0][21],
                            Yoy: data.Data.data[0][22] * 100,

                            ShareDrafts: data.Data.data[0][24] / 100,
                            RegularShares: data.Data.data[0][25] / 100,
                            MoneyMarketShares: data.Data.data[0][26] / 100,
                            ShareCertificates: data.Data.data[0][27] / 100,
                            Ira: data.Data.data[0][28] / 100,
                            AverageCostFunds: data.Data.data[0][29] * 100,

                            CreditCards: data.Data.data[0][31] / 100,
                            UnsecuredCredit: data.Data.data[0][32] / 100,
                            NewAutosLoan: data.Data.data[0][33] / 100,
                            UsedAutosLoan: data.Data.data[0][34] / 100,
                            Mortgage: data.Data.data[0][35] / 100,
                            OtherRealEstate: data.Data.data[0][36] / 100,
                            StudentLoans: data.Data.data[0][37] / 100,
                            LeasesReceivable: data.Data.data[0][38] / 100,
                            AverageLoanYield: data.Data.data[0][39] * 100,

                            //member value
                            ReturnMembers: data.Data.data[0][41],
                            ReturnSavers: data.Data.data[0][42],
                            ReturnBorrowers: data.Data.data[0][43],
                            ServiceUsage: data.Data.data[0][44],

                            DividendsByInterestIncome: data.Data.data[0][46] * 100,
                            LoanInterest: data.Data.data[0][47] * 100,
                            InvestmentInterest: data.Data.data[0][48] * 100,
                            NonInterestIncome: data.Data.data[0][49] * 100,
                            NetIncomeByTotalOperatinExp: data.Data.data[0][50] * 100,

                            ShareDraftPenetration: data.Data.data[0][52] * 100,
                            AutoLoanPenetration: data.Data.data[0][53] * 100,
                            CreditCardPenetration: data.Data.data[0][54] * 100,
                            FirstMortgagePenetration: data.Data.data[0][55] * 100,


                            //throughout
                            FTEmployees: data.Data.data[0][58],
                            MembersPerEmployee: data.Data.data[0][59],
                            AssetPerEmployee: data.Data.data[0][60],
                            SharePerFTE: data.Data.data[0][61],
                            LoanPerFTE: data.Data.data[0][62],
                            IncomePerFTE: data.Data.data[0][63],
                            NIM: data.Data.data[0][64],
                            NetIncomePerFTE: data.Data.data[0][65],
                            CapitalPerFTE: data.Data.data[0][66],
                            AverageSalaryPerFTE: data.Data.data[0][67],
                            RevenuePerFTE: data.Data.data[0][68],
                            YoyPerFTE: data.Data.data[0][69] * 100,

                            TotalAssets: data.Data.data[0][71] * 100,
                            OperatingExpensePerMember: data.Data.data[0][72],
                            EfficiencyRatio: data.Data.data[0][73] * 100,
                            OperatinExpenseAverageAssets: data.Data.data[0][74] * 100,
                            AverageAccountSize: data.Data.data[0][75],
                            YoyGrowthInAverageAccountSize: data.Data.data[0][76] * 100,
                            RevenueByOperatingExpense: data.Data.data[0][77] * 100,
                            RevenuePerMember: data.Data.data[0][78],
                            CapitalPerMember: data.Data.data[0][79],
                            NetIncomePerMember: data.Data.data[0][80],

                            //delivery
                            NumberOfBranches: data.Data.data[0][82],
                            YoyChange: data.Data.data[0][83],
                            LandPerBranch: data.Data.data[0][84],
                            EmployeesPerBranch: data.Data.data[0][85],
                            MembersPerBranch: data.Data.data[0][86],
                            NetNewMembersPerBranch: data.Data.data[0][87],
                            MemberOfSharedBranchNetwork: data.Data.data[0][88],

                            //lending
                            LoansShareRatio: data.Data.data[0][91] * 100,
                            LoansByShares: data.Data.data[0][92] * 100,
                            IndirectLoansByTotalLoans: data.Data.data[0][93] * 100,
                            InterestIncomePerLoanAccount: data.Data.data[0][94],
                            LoansByTotalLoans: data.Data.data[0][95] * 100,
                            YoyGrowthOfLoans: data.Data.data[0][96] * 100,
                            AverageLoanOriginated: data.Data.data[0][97],

                            NewAutosComposition: data.Data.data[0][99] * 100,
                            UsedAutosComposition: data.Data.data[0][100] * 100,
                            Mortgage1st: data.Data.data[0][101] * 100,
                            OtherRealEstateComposition: data.Data.data[0][102] * 100,
                            CreditCardsComposition: data.Data.data[0][103] * 100,
                            OtherUnsecured: data.Data.data[0][104] * 100,
                            BusinessLoans: data.Data.data[0][105] * 100,
                            PrivateStudentLoans: data.Data.data[0][106] * 100,
                            LeasesReceivableComposition: data.Data.data[0][107] * 100,

                            DelinquentLoansByTotalLoans: data.Data.data[0][109] * 100,
                            NetChargeByAverageLoans: data.Data.data[0][110] * 100
                        };
                    }
                }
            }, function (error) {
                // error handling
            });

            //api.creditunion.financial(vm.cdsId, vm.memberId).then(function (data) {
            //    if (!angular.isUndefined(data.Data.data)) {
            //        api.creditunion.cycleName(data.Data.data[0][0]).then(function (dateData) {
            //            vm.DataDate = dateData.Data;
            //            if (vm.DataDate == '')
            //                vm.Message = 'No data';
            //        });
                    
            //        //vm.Financial = data.Data.data;
            //        vm.Financial = {
            //            Assets: data.Data.data[0][4],
            //            Loans: data.Data.data[0][5],
            //            Investments: data.Data.data[0][36],
            //            Capital: data.Data.data[0][35],
            //            Members: data.Data.data[0][13],
            //            Employees: data.Data.data[0][37],
            //            ShareGrowth: data.Data.data[0][8] * 100,
            //            LoanGrowth: data.Data.data[0][7] * 100,
            //            Roa: data.Data.data[0][9] * 100
            //        };
            //    }
            //}, function (error) {
            //    // error handling
            //});



            //locked content
            if (vm.isExclusiveMember) {

                api.creditunion.technologypartner(vm.cdsId, vm.memberId).then(function (data) {
                    vm.Vendor = data.Data;
                }, function (error) {
                    // error handling
                });

                //api.creditunion.scorecard(vm.cdsId, vm.memberId).then(function (data) {
                //    vm.ScorecardDate = "get scorecard date!";
                //    vm.Scorecard = {
                //        CdsId: vm.cdsId,

                //        //growth
                //        Shares: data.Data.data[0][4] * 100,
                //        Loans: data.Data.data[0][5] * 100,
                //        Assets: data.Data.data[0][6] * 100,
                //        Capital: data.Data.data[0][7] * 100,

                //        TotalRevenue: data.Data.data[0][9] * 100,
                //        OperatingExpenses: data.Data.data[0][10] * 100,
                //        LoanLoss: data.Data.data[0][11] * 100,
                //        Dividends: data.Data.data[0][12] * 100,
                //        NetIncome: data.Data.data[0][13] * 100,

                //        //member metrics
                //        MemberGrowth: data.Data.data[0][15] * 100,
                //        PotentialMembers: data.Data.data[0][16] * 100,
                //        SharePerMember: data.Data.data[0][17],
                //        AverageShareBalance: data.Data.data[0][18],
                //        LoanPerMember: data.Data.data[0][19],
                //        AverageLoanBalance: data.Data.data[0][20],
                //        AverageMemberRelationship: data.Data.data[0][21],
                //        Yoy: data.Data.data[0][22] * 100,

                //        ShareDrafts: data.Data.data[0][24] / 100,
                //        RegularShares: data.Data.data[0][25] / 100,
                //        MoneyMarketShares: data.Data.data[0][26] / 100,
                //        ShareCertificates: data.Data.data[0][27] / 100,
                //        Ira: data.Data.data[0][28] / 100,
                //        AverageCostFunds: data.Data.data[0][29] * 100,

                //        CreditCards: data.Data.data[0][31] / 100,
                //        UnsecuredCredit: data.Data.data[0][32] / 100,
                //        NewAutosLoan: data.Data.data[0][33] / 100,
                //        UsedAutosLoan: data.Data.data[0][34] / 100,
                //        Mortgage: data.Data.data[0][35] / 100,
                //        OtherRealEstate: data.Data.data[0][36] / 100,
                //        StudentLoans: data.Data.data[0][37] / 100,
                //        LeasesReceivable: data.Data.data[0][38] / 100,
                //        AverageLoanYield: data.Data.data[0][39] * 100,

                //        //member value
                //        ReturnMembers: data.Data.data[0][41],
                //        ReturnSavers: data.Data.data[0][42],
                //        ReturnBorrowers: data.Data.data[0][43],
                //        ServiceUsage: data.Data.data[0][44],

                //        DividendsByInterestIncome: data.Data.data[0][46] * 100,
                //        LoanInterest: data.Data.data[0][47] * 100,
                //        InvestmentInterest: data.Data.data[0][48] * 100,
                //        NonInterestIncome: data.Data.data[0][49] * 100,
                //        NetIncomeByTotalOperatinExp: data.Data.data[0][50] * 100,

                //        ShareDraftPenetration: data.Data.data[0][52] * 100,
                //        AutoLoanPenetration: data.Data.data[0][53] * 100,
                //        CreditCardPenetration: data.Data.data[0][54] * 100,
                //        FirstMortgagePenetration: data.Data.data[0][55] * 100,


                //        //throughout
                //        FTEmployees: data.Data.data[0][58],
                //        MembersPerEmployee: data.Data.data[0][59],
                //        AssetPerEmployee: data.Data.data[0][60],
                //        SharePerFTE: data.Data.data[0][61],
                //        LoanPerFTE: data.Data.data[0][62],
                //        IncomePerFTE: data.Data.data[0][63],
                //        NIM: data.Data.data[0][64],
                //        NetIncomePerFTE: data.Data.data[0][65],
                //        CapitalPerFTE: data.Data.data[0][66],
                //        AverageSalaryPerFTE: data.Data.data[0][67],
                //        RevenuePerFTE: data.Data.data[0][68],
                //        YoyPerFTE: data.Data.data[0][69] * 100,

                //        TotalAssets: data.Data.data[0][71] * 100,
                //        OperatingExpensePerMember: data.Data.data[0][72],
                //        EfficiencyRatio: data.Data.data[0][73] * 100,
                //        OperatinExpenseAverageAssets: data.Data.data[0][74] * 100,
                //        AverageAccountSize: data.Data.data[0][75],
                //        YoyGrowthInAverageAccountSize: data.Data.data[0][76] * 100,
                //        RevenueByOperatingExpense: data.Data.data[0][77] * 100,
                //        RevenuePerMember: data.Data.data[0][78],
                //        CapitalPerMember: data.Data.data[0][79],
                //        NetIncomePerMember: data.Data.data[0][80],

                //        //delivery
                //        NumberOfBranches: data.Data.data[0][82],
                //        YoyChange: data.Data.data[0][83],
                //        LandPerBranch: data.Data.data[0][84],
                //        EmployeesPerBranch: data.Data.data[0][85],
                //        MembersPerBranch: data.Data.data[0][86],
                //        NetNewMembersPerBranch: data.Data.data[0][87],
                //        MemberOfSharedBranchNetwork: data.Data.data[0][88],

                //        //lending
                //        LoansShareRatio: data.Data.data[0][91] * 100,
                //        LoansByShares: data.Data.data[0][92] * 100,
                //        IndirectLoansByTotalLoans: data.Data.data[0][93] * 100,
                //        InterestIncomePerLoanAccount: data.Data.data[0][94],
                //        LoansByTotalLoans: data.Data.data[0][95] * 100,
                //        YoyGrowthOfLoans: data.Data.data[0][96] * 100,
                //        AverageLoanOriginated: data.Data.data[0][97],

                //        NewAutosComposition: data.Data.data[0][99] * 100,
                //        UsedAutosComposition: data.Data.data[0][100] * 100,
                //        Mortgage1st: data.Data.data[0][101] * 100,
                //        OtherRealEstateComposition: data.Data.data[0][102] * 100,
                //        CreditCardsComposition: data.Data.data[0][103] * 100,
                //        OtherUnsecured: data.Data.data[0][104] * 100,
                //        BusinessLoans: data.Data.data[0][105] * 100,
                //        PrivateStudentLoans: data.Data.data[0][106] * 100,
                //        LeasesReceivableComposition: data.Data.data[0][107] * 100,

                //        DelinquentLoansByTotalLoans: data.Data.data[0][109] * 100,
                //        NetChargeByAverageLoans: data.Data.data[0][110] * 100
                //    };
                //}, function (error) {
                //    // error handling
                //});

                api.creditunion.branch(vm.cdsId, vm.memberId).then(function (data) {
                    vm.Branches = data.Data;
                }, function (error) {
                    // error handling
                });
            }

        }

        function toggleTab(tab) {
            if (tab == 1)
                vm.financialTab = !vm.financialTab;
            else if (tab == 2)
                vm.executiveTab = !vm.executiveTab;
            else if (tab == 3)
                vm.technologyTab = !vm.technologyTab;
            else if (tab == 4)
                vm.scorecardTab = !vm.scorecardTab;
            else
                vm.branchTab = !vm.branchTab;
        }

        function backToSearch() {
            $location.path("cusearch/");
        }


    }
})();