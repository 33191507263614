(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('searchCu', searchCuDirective);

    function searchCuDirective() {
        return {
            scope: {},
            templateUrl: 'demo/search/searchCu.html',
            controller: 'SearchCuCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('SearchCtrl', SearchCtrl);

    SearchCtrl.$inject = ['titleCaseFilter', '$location', 'api', 'cuUser', '$routeParams'];
    function SearchCtrl(titleCaseFilter, $location, api, cuUser, $routeParams) {
        var vm = this;
        vm.path = $location.path();
        vm.switchTab = switchTab;
        vm.onStateChange = onStateChange;
        vm.goToDetail = goToDetail;
        vm.cities = [];
        //vm.redirect = redirect;
        vm.searchCu = searchCu;
        vm.searchExecutive = searchExecutive;
        vm.showCuResult = false;
        vm.showExecResult = false;       
        vm.access = true;
        vm.executiveaccess = true;
        vm.result = [];
        vm.error = false;
        vm.noaccessmessage = "NO ACCESS! This is a paid feature on CreditUnions.com."
        vm.model = {
            ExecutiveName: '',
            ExecutiveState: '',
            State: '',
            City: '',
            CuName: ''
        };
        init();

        function init() {
            //enforce login          
            vm.memberId = cuUser.memberId() == null ? 0 : cuUser.memberId();
            vm.loggedin = cuUser.memberId() == null ? false : true;
            if (!vm.loggedin) {
                if (vm.path == '/cusearch/')
                    $location.path("account/0/login/8");
                else if (vm.path == '/bystate/')
                    $location.path("account/0/login/9");
                else
                    $location.path("account/0/login/10");
                return;
            }

            vm.activeTab = 1;

            if (vm.path.indexOf('/cusearch/') > -1)                 
                switchTab(1);
             else if (vm.path.indexOf('/bystate/') > -1) 
                switchTab(2);
             else //executive search
            {
                switchTab(3);
            }
            api.city.getStates().then(function(data) {
                vm.states = data.Data;
                },
                function(error) {
                    // error handling
                    vm.content = "error";
                });           
            if (vm.memberId > 0) {
                api.member.getAccessDetails(vm.memberId).then(function (data) {
                    if (data != null && data !== 0) {
                        if (data.companysubscription == "N") {
                            if (data.companytype == "S")
                                vm.access = false;
                            else if (data.companytype == "CU")
                                vm.access = true;
                                vm.executiveaccess = false;
                        }
                        else if (data.companytype == "S" && data.companysubscription == "SC" && data.productaccess == false) {
                            vm.access = false;
                            vm.executiveaccess = false;
                        } 
                    }
                    else
                        return;
                }, function (error) {
                });
            }
            else
            {
                vm.access = false;
                vm.executiveaccess = false;
            }
            //check if any params are attached ("search" button clicked state)
            if ($routeParams.name != null) {
                if (vm.path.indexOf('/cusearch/') > -1) {
                    vm.model.CuName = $routeParams.name;
                    searchCu();
                }
                else if (vm.path.indexOf('/bystate/') > -1) {
                    vm.model.State = $routeParams.state;
                    vm.model.City = $routeParams.name;
                    searchCu();
                } else {
                    vm.model.ExecutiveState = $routeParams.state;
                    vm.model.ExecutiveName = $routeParams.name;
                    searchExecutive();
                }
                

            }
        }
        //------------------------------------------------------------------------

        function switchTab(tabId) {           
            if (vm.activeTab == tabId) return;
            vm.activeTab = tabId;
            var type;
            switch (tabId) {
                case 1: type = 'cusearch';
                    break;
                case 2: type = 'bystate';
                    break;
                case 3: type = 'executive';
                    break;
                default: console.error('Unknown tab selection');
            }           
            $location.path("/" + type + "/");
        }

        //------------------------------------------------------------------------
        //function redirect(type) {
        //    api.search.cu(vm.model).then(function (data) {
        //        vm.result = data.Data;
        //        if (vm.result.length === 1) { //one result --> navigate to the detail page directly
        //            goToDetail(vm.result[0]);
        //            return;
        //        } else {
        //            if (type === 'cusearch')
        //                if (vm.model.CuName === '') vm.error = true;
        //                else $location.path("/" + type + "/" + vm.model.CuName);
        //            else if (type === 'bystate')
        //                $location.path("/" + type + "/" + (vm.model.City === '' ? '0' : vm.model.City) + "/" + (vm.model.State === '' ? '0' : vm.model.State));
        //            else
        //            if (vm.model.ExecutiveName === '') vm.error = true;
        //            else $location.path("/" + type + "/" + vm.model.ExecutiveName + "/" + (vm.model.ExecutiveState === '' ? '0' : vm.model.ExecutiveState));
        //        }
        //        }, function (error) {
        //        // error handling
        //        console.log('error');
        //    });
        //}

        //------------------------------------------------------------------------

        function onStateChange() {
            api.city.getCitiesByState(vm.model.State).then(function (data) {
                vm.cities = data.Data;
                angular.forEach(vm.cities, function (value, key) {
                    value.ItemName = titleCaseFilter(value.ItemName);
                    vm.cities[key] = value;
                });
            }, function (error) {
                // error handling

            });
        }

        //------------------------------------------------------------------------
        function searchCu() {
            api.search.cu(vm.model).then(function (data) {
               
                vm.result = data.Data;
                vm.ResultTotal = data.Data.length;

                angular.forEach(vm.result, function (value, key) {
                    value.City = titleCaseFilter(value.City);
                    vm.result[key] = value;
                });

                if (vm.ResultTotal === 1) //one result --> navigate to the detail page directly
                    goToDetail(vm.result[0]);

                //console.log(vm.result);
            }, function (error) {
                // error handling
                console.log('error');
            });

            vm.showCuResult = true; 
        }

        //------------------------------------------------------------------------

        function searchExecutive() {
            api.search.executive(vm.model).then(function (data) {
                vm.showExecResult = true;
                vm.execList = data.Data;
                vm.execResultTotal = data.Data.length;

                if (vm.execResultTotal === 1)
                    goToDetail(vm.execList[0]);

            }, function (error) {
                // error handling

            });
        }

        function goToDetail(cu) {
            $location.path("search/" + cu.CdsId + "/detail/" + cu.TitleUrl);
        }
    }
})();
