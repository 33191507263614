
(function () {
    'use strict';
    var app = angular.module("creditunionsmobile");
    app.directive("sponsoredListDirective", function () {
        return {
            scope: {
                typeId: '='

            },
            templateUrl: 'read/sponsoredList.html',
            controller: 'SponsoredCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('creditunionsmobile')
        .controller('SponsoredCtrl', SponsoredCtrl);

    SponsoredCtrl.$inject = ['api', '$scope', '$location', 'cuUser', 'toolId', '$sce', '$routeParams'];

    function SponsoredCtrl(api, $scope, $location, cuUser, toolId, $sce, $routeParams) {

        var vm = this;
        vm.itemId = parseInt($routeParams.id);
        vm.goToArticle = goToArticle;
        //vm.selectPage = selectPage;
        vm.pageNumber = 1;
        vm.memberId = null;
        vm.show = true;
        init();

        //--------------- BEGIN canonical tag ----------------------
        //var metaTag = document.createElement('link');
        //metaTag.rel = "canonical";
        //metaTag.href = "http://www.creditunions.com/articlelist";
        //document.getElementsByTagName('head')[0].appendChild(metaTag);
        //--------------- END canonical tag ----------------------

        function goToArticle(articleItem) {
            vm.memberId = cuUser.memberId();
            if ((articleItem
                    .ContentType ===
                    'E' ||
                    articleItem.ContentType === 'L') &&
                angular.isUndefined(vm.memberId)) {
                $location.path("account/" + articleItem.ItemId + '/login/' + articleItem.ToolId);
            } else if (articleItem.TitleUrl !== null) {

                //if (articleItem.ToolId === toolId.Blog)
                //    $location.path(articleItem.ItemId + "/blog/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.News)
                //    $location.path(articleItem.ItemId + "/article/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.Video)
                //    $location.path(articleItem.ItemId + "/video/" + articleItem.TitleUrl);
                //else if (articleItem.ToolId === toolId.Press)
                //    $location.path(articleItem.ItemId + "/press/" + articleItem.TitleUrl);

                if (articleItem.ToolId === toolId.Blog)
                    $location.path(articleItem.ItemId + articleItem.TitleUrl);
                else if (articleItem.ToolId === toolId.News)
                    $location.path(articleItem.ItemId + articleItem.TitleUrl);
                else if (articleItem.ToolId === toolId.Press)
                    $location.path(articleItem.TitleUrl);
            }
        }

        function init() {
            api.article.getByCompany(vm.itemId).then(function (data) {
                vm.contentTop = data.Data.slice(0, 5);
                vm.contentBottom = data.Data.slice(5, 10);
            }, function (error) {
                // error handling
                vm.content = "error";
            });
        }
    }
})();