(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('VideoCtrl', VideoCtrl);

    VideoCtrl.$inject = ['api', '$location', '$routeParams', 'cuUser', '$sce'];
    function VideoCtrl(api, $location, $routeParams, cuUser, $sce) {
        var vm = this;
        vm.path = $location.path();
        vm.itemId = parseInt($routeParams.id);
        vm.content = {};
        vm.memberId = 0;
        vm.memberId = cuUser.memberId();

        api.video.getById(vm.itemId, vm.memberId).then(function (data) {
            vm.content = data.Data;
            vm.content.VideoUrl = $sce.trustAsResourceUrl(data.Data.VideoUrl);
            vm.content.Body = $sce.trustAsHtml(data.Data.Body);
        }, function (error) {
            // error handling
            vm.content = "error";
        });



    }
})();