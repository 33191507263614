(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .controller('AuthorCtrl', ['$location', 'api', '$routeParams', '$sce', 'cuUser', 'toolId', function ($location, api, $routeParams, $sce, cuUser, toolId) {
            var vm = this;
            vm.authorId = parseInt($routeParams.id);
            vm.content = {};
            vm.goToArticle = goToArticle;
            vm.selectArticlePage = selectArticlePage;
            vm.selectBlogPage = selectBlogPage;
            vm.activeTab = 1;
            vm.Pages = {ArticlePageNum: 1, BlogPageNum : 1};
            vm.totalArticleItems = 0;
            vm.totalBlogItems = 0;

            api.author.get(vm.authorId, vm.Pages).then(function (data) {
                vm.content = data.Data;
                vm.totalArticleItems = data.Data.TotalArticles;
                vm.totalBlogItems = data.Data.TotalBlogs;
                vm.content.Bio = $sce.trustAsHtml(data.Data.Bio);
            }, function (error) {
                // error handling
                vm.content = "error";
            });


            function goToArticle(articleItem) {
                vm.memberId = cuUser.memberId();
                if ((articleItem.ContentType == 'E' || articleItem.ContentType == 'L') && angular.isUndefined(vm.memberId)) {
                    $location.path("account/" + articleItem.ItemId + '/login/' + articleItem.ToolId);
                }

                else {
                    if (articleItem.ToolId == toolId.Blog)
                        $location.path(articleItem.ItemId + articleItem.TitleUrl);
                    else if (articleItem.ToolId == toolId.News)
                        $location.path(articleItem.ItemId + articleItem.TitleUrl);
                    if (articleItem.ToolId == toolId.Video)
                        $location.path(articleItem.ItemId + articleItem.TitleUrl);
                }
            }

            function selectArticlePage(pageNum) {
                //vm.Pages.ArticlePageNum = pageNum;

                api.author.get(vm.authorId, vm.Pages).then(function (data) {
                    vm.content.Articles = data.Data.Articles;
                }, function (error) {
                    // error handling
                    vm.content = "error";
                });

            }
            function selectBlogPage(pageNum) {
                vm.Pages.BlogPageNum = pageNum;

                api.author.get(vm.authorId, vm.Pages).then(function (data) {
                    vm.content.Blogs = data.Data.Blogs;
                }, function (error) {
                    // error handling
                    vm.content = "error";
                });

            }

        }]);
})();