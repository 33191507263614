(function () {
    'use strict';


    angular.module('creditunionsmobile')
        .controller('PageCtrl', PageCtrl);

    PageCtrl.$inject = ['api', '$location', '$routeParams', 'cuUser', '$sce'];
    function PageCtrl(api, $location, $routeParams, cuUser, $sce) {
        var vm = this;
        vm.param = {
            Url: $location.path(),
            PageId: 0
        }

        api.page.getPageByUrl(vm.param).then(function (data) {
            vm.content = $sce.trustAsHtml(data.Data.ContentHtml);
        }, function (error) {
            // error handling
            vm.content = "error";
        });

    }
})();
