(function () {
    'use strict';

    angular.module('creditunionsmobile')
        .directive('forgotPassword', forgotPasswordDirective);

    function forgotPasswordDirective() {
        return {
            scope: {},
            templateUrl: 'demo/account/forgotPassword.html',
            controller: 'ForgotPasswordCtrl',
            controllerAs: 'vm'
        };
    }

    angular.module('creditunionsmobile')
        .controller('ForgotPasswordCtrl', ForgotPasswordCtrl);

    ForgotPasswordCtrl.$inject = ['cuUser', 'api'];
    function ForgotPasswordCtrl(cuUser, api) {
        var vm = this;
        vm.profile = {};





    }
})();
